import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { openInfoPage } from '../../app/header/info/Info';
import { InfoDialogContentType } from '../../app/header/info/InfoDialogDisplaySection';
import { FeedbackOriginEnum } from '../../microfrontends/FeedbackCareMfe';
import { useEngineOilPreconditionsDialog } from '../hooks/useEngineOilPreconditionsDialog';
import EngineOilPreconditionsDialog from './vehicleData/EngineOilPreconditionsDialog';
import { FillLevelType } from '../../../generated/fleetAggregationServiceTypes';
import { useNavigate } from 'react-router';

type EngineOilPreConditionsAndFeedbackProps = {
    engineOilType: FillLevelType;
};

export const EngineOilPreConditionsAndFeedback = ({ engineOilType }: EngineOilPreConditionsAndFeedbackProps) => {
    const isEngineOilDid = engineOilType === FillLevelType.EngineOilDid;
    const navigate = useNavigate();

    const { engineOilPreconditionsDialog, openEngineOilPreconditionsDialog, closeEngineOilPreconditionsDialog } = useEngineOilPreconditionsDialog();
    return (
        <div className="text-right text-color-primary">
            <span className="cursor-pointer" onClick={openEngineOilPreconditionsDialog}>
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions" />
            </span>
            {isEngineOilDid && renderEngineOilFeedback()}
            <EngineOilPreconditionsDialog openDialog={engineOilPreconditionsDialog} closeDialog={closeEngineOilPreconditionsDialog} engineOilType={engineOilType} />
        </div>
    );

    function renderEngineOilFeedback() {
        return (
            <>
                <span className="separator" style={{ padding: '1px 8px' }}>
                    |
                </span>
                <Button onClick={handleClickFeedbackForm}>
                    <span className="rioglyph rioglyph-thumbs-up" />
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.feedback.button.rateYourExperience" />
                </Button>
            </>
        );
    }

    function handleClickFeedbackForm() {
        openInfoPage(navigate, location.search, InfoDialogContentType.feedback, FeedbackOriginEnum.ENGINE_OIL);
    }
};
