import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';

type InformationSignWithOutdatedTooltipProps = {
    outdatedHours: number;
    tooltipPosition?: string;
};

export const InformationSignWithOutdatedTooltip = ({ outdatedHours, tooltipPosition }: InformationSignWithOutdatedTooltipProps) => {
    const tooltip = (
        <Tooltip className="width-40ch">
            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.olderThanXHours'} values={{ hours: outdatedHours }} />
        </Tooltip>
    );
    return (
        <>
            &nbsp;
            <OverlayTrigger placement={tooltipPosition} overlay={tooltip}>
                <span className="icon rioglyph rioglyph-info-sign text-color-dark" />
            </OverlayTrigger>
        </>
    );
};
