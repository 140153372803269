import countryCodes from './country-codes.json';

export function getPhoneCountryCode(alpha2?: string) {
    const matchedCodes = countryCodes.filter((countryCode) => countryCode.alpha2 === alpha2);

    if (matchedCodes.length === 0) {
        return '+';
    }
    return matchedCodes[0].callingCode;
}
