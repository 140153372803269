import IframeResizer from 'iframe-resizer-react';
import { STENCIL_TEST_MFE } from './microfrontendsConfig';
import { memo } from 'react';

export const StencilTestMfe = memo(() => {
    return (
        <IframeResizer
            src={STENCIL_TEST_MFE.url}
            checkOrigin={false}
            style={{
                width: '1px',
                minWidth: '100%',
                border: '0',
            }}
        />
    );
});
