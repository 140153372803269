import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';
import { CustomDateTime } from '../data/formatting/CustomDateTime';

type NextAppointmentWidgetProps = {
    appointment?: Date | string;
    showText?: boolean;
};

const NextAppointmentWidget = ({ appointment, showText = false }: NextAppointmentWidgetProps) => {
    if (!appointment) return null;
    const isPastAppointment = new Date().getTime() > new Date(appointment).getTime();

    return (
        <span className={isPastAppointment ? 'text-color-danger' : ''}>
            {isPastAppointment && (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip className="bottom">
                            <FormattedMessage id="intl-msg:fleetstatus.global.appointments.overdue_tooltip" />
                        </Tooltip>
                    }
                >
                    <span className="rioglyph rioglyph-time margin-right-5" />
                </OverlayTrigger>
            )}
            {showText && (
                <span className="margin-right-5">
                    <FormattedMessage id="intl-msg:fleetstatus.global.appointments.appointment_at" />
                </span>
            )}
            <span data-testid="next-appointment-widget-date">
                <CustomDateTime value={appointment} />
            </span>
        </span>
    );
};

export default NextAppointmentWidget;
