/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Asset {
    /** @format uuid */
    id: string;
    /**
     * The name of the asset.
     * @example TGX blue
     */
    name: string;
    /**
     * The vin of the asset.
     * @example WMA03VUY1H9001088
     */
    vin?: string;
    /**
     * The manufacturer of this asset.
     * @example MAN
     */
    manufacturer?: string;
    /** Types of supported workshop businesses */
    type?: AssetType;
    /** Defines the product that is booked for this asset or null/undefined if no service product is booked for this asset. */
    product?: Product;
    /** Identifies if the vehicle is electric or not */
    isElectric?: boolean;
    /** Identifies if the vehicle has OCU3 device type or not */
    hasOCU3DeviceType?: boolean;
    /** Identifies if the vehicle has CM4 device type or not */
    hasCM4DeviceType?: boolean;
    /** The variant of vehicle */
    vehicleVariant?: VehicleVariant;
}

export type AssetIdList = string[];

export interface BookingOverview {
    /** Number of all assets of the account. */
    number_all_assets: number;
    /** Number of assets with booked ServiceCare S. */
    number_service_care_s: number;
    /** Number of assets with booked ServiceCare M. */
    number_service_care_m: number;
    /** Number of assets with booked ServiceCare L. */
    number_service_care_l: number;
    /** Number of all assets without a booking. */
    number_no_product_booking: number;
}

export type CustomComponent = DateBasedCustomComponent | DistanceBasedCustomComponent;

export interface DateBasedCustomComponent {
    /** @format uuid */
    id?: string;
    /** @example Test crane structure */
    name: string;
    measured_at?: MeasuredAt;
    /** @example date */
    type: 'date';
    /** Interval based on distance or time at which maintenance needs to performed. */
    recurrence?: {
        /** @example 6 */
        interval: number;
        /** @example month */
        interval_type: 'year' | 'month' | 'week';
    };
    /** @format uuid */
    asset_id: string;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality?: Criticality;
    /** @format date-time */
    almost_due_starts_at_date: string;
    /** @format date-time */
    due_at_date: string;
}

export interface DistanceBasedCustomComponent {
    /** @format uuid */
    id?: string;
    /** @example Test crane structure */
    name: string;
    measured_at?: MeasuredAt;
    /** @example date */
    type: 'distance';
    /** Interval based on distance or time at which maintenance needs to performed. */
    recurrence?: {
        /** @example 6 */
        interval: number;
        /** @example month */
        interval_type: 'distance';
    };
    /** @format uuid */
    asset_id: string;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality?: Criticality;
    almost_due_starts_at_mileage_km: number;
    due_at_mileage_km: number;
}

export interface MeasuredAt {
    /**
     * @format float
     * @example 57255.48
     */
    mileage_km?: number;
    /** @format date-time */
    date?: string;
}

export type DriverIdList = string[];

export interface DamageListRequestBody {
    asset_ids?: AssetIdList;
    driver_ids?: DriverIdList;
}

/**
 * The different content type of a diagnostics export.
 */
export enum DiagnosticsExportContentItem {
    MaintenanceComponents = 'maintenance_components',
    CustomComponents = 'custom_components',
    BrakeLinings = 'brake_linings',
    FillLevels = 'fill_levels',
    BatteryOverview = 'battery_overview',
}

export interface DiagnosticDetail {
    asset: Asset;
    /** A complete component object for the diagnostics sidebar */
    components: Components;
    /** A complete brake lining object to be displayed in the sidebar */
    brake_linings: BrakeLinings;
    /** A complete fill level object for the diagnostics sidebar */
    engine_oil?: FillLevels;
    /** A complete fill level object for the diagnostics sidebar */
    fill_levels: FillLevels;
    /** A complete tire pressure object to be displayed in the sidebar */
    tire_pressures?: TirePressures;
    /** An array of appointments with details; ordered by date (ascending). Only appointments with components or damages attached are returned. */
    appointments: Appointments;
    latest_events?: MaintenanceItemUpdateProps;
    /**
     * The name of the assigned workshop or null, if no workshop is assigned.
     * @example MAN Truck & Bus Service München-Lochham
     */
    assigned_workshop_name?: string;
}

export interface DiagnosticsListRequestBody {
    asset_ids?: AssetIdList;
}

export interface PhoneNumbersResponse {
    items: {
        /**
         * Value only exists if valid is true. The format style is PhoneNumberFormat.INTERNATIONAL
         * @example +41 44 668 18 00
         */
        formatted?: string;
        valid: boolean;
    }[];
}

export interface AssetSummary {
    asset: Asset;
    /**
     * The name of the assigned workshop or null, if no workshop is assigned.
     * @example MAN Truck & Bus Service München-Lochham
     */
    assigned_workshop_name?: string;
    /**
     * The first and last name of a workshops contact person or null, if no contact person is assigned.
     * @example Michael Müller
     */
    workshop_contact_person_name?: string;
    /**
     * An enum indicating if maintenance data is sync on this asset: * `NO_DATA_AVAILABLE` - Latest date from maintenance older than 24 hours * `OUT_OF_SYNC` - Latest date from maintenance is not older than 24 hours * `RECENTLY_SYNCED` - Latest date from maintenance 24 to 72 hours * `SYNCED` - Synced
     *
     * @example SYNCED
     */
    transfer_status: 'NO_DATA_AVAILABLE' | 'OUT_OF_SYNC' | 'RECENTLY_SYNCED' | 'SYNCED';
}

export interface AssetTreeResponse {
    assets: AssetTreeAssets;
    drivers: AssetTreeDrivers;
}

export interface AssetTreeAssets {
    items: AssetTreeAssetItem[];
    groups: AssetTreeAssetGroup[];
}

export interface AssetTreeDrivers {
    items: AssetTreeDriverItem[];
}

export interface AssetTreeAssetItem {
    /** The id of an asset */
    id: string;
    /** The name of an asset */
    name: string;
    /** Types of supported workshop businesses */
    type: AssetType;
    /** An array of ids to which the asset is assigned to. See AssetTreeAssetGroup. */
    groupIds: string[];
}

export interface AssetTreeAssetGroup {
    /** The id of an asset group */
    id: string;
    /** The name of an asset group */
    name: string;
    position?: 'last';
    /** If group is disabled for selection */
    disabled: boolean;
}

export interface AssetDetailExtension {
    general_data: AssetGeneralData;
    service_contract?: ServiceContract;
    liabilities: Warranty[];
    purchased_warranties: Warranty[];
    links: ContractAndWarrantyLinks;
}

export interface AssetTreeDriverItem {
    /** The id of the driver */
    id: string;
    name: AssetTreeDriverName;
    type: 'driver';
}

export interface AssetTreeDriverName {
    firstName?: string;
    lastName: string;
}

export interface Damage {
    /** @format uuid */
    id: string;
    asset: Asset;
    /**
     * Name of the driver who reported the damage
     * @example George Letton
     */
    driver_name?: string;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
    /**
     * The current status of the damage. Note that appointment_done is a 'virtual' processing state.
     * It indicates status open + an appointment containing the damage is marked as done.
     *
     */
    processing_state?: DamageProcessingState;
    /**
     * Short description of the damage (translated by the backend)
     * @example Door falls down.
     */
    description: string;
    /**
     * Date when the damage occurred
     * @format date-time
     * @example 2019-07-04T08:52:33.123Z
     */
    occurred_at_date?: string;
    /** Images */
    attachment_ids?: string[];
    /**
     * The timestamp of the earliest open appointment (upcoming or overdue) for this damage.
     * Only populated for damages with status open.
     *
     * @format date-time
     */
    next_appointment?: string;
    /**
     * The date when the damage was fixed.
     * The value of the last done appointment for the damage. If there is no done appointment, the value does not exist.
     *
     * @format date-time
     */
    fixed_at_date?: string;
}

export type DamageDetail = Damage & {
    /**
     * Description of the nature of the damage (translated by the backend)
     * @example Truck door
     */
    classification_description?: string | null;
    /**
     * Additional information of the damage.
     * @example When I open the door it falls down on the floor and then it is broken.
     */
    user_notes?: string;
    /** An array of appointments with details; ordered by date (ascending). Only appointments with components or damages attached are returned. */
    appointments: Appointments;
};

/**
* The current status of the damage. Note that appointment_done is a 'virtual' processing state.
It indicates status open + an appointment containing the damage is marked as done.
* @example open
*/
export enum DamageProcessingState {
    New = 'new',
    Open = 'open',
    AppointmentDone = 'appointment_done',
    Fixed = 'fixed',
    WontFix = 'wont_fix',
}

export interface AssetSummaryListResponse {
    items: AssetSummary[];
    cursors: Cursors;
}

export interface DamageListResponse {
    items: Damage[];
    cursors: Cursors;
}

export interface DiagnosticComponent {
    /** The component id (UUID) */
    id: string;
    asset: Asset;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
    /** The name of a (custom) component */
    component_name: string;
    /**
     * The date until this component is due
     * @format date-time
     * @example 2019-07-04T08:52:33.123Z
     */
    due_at?: string;
    /**
     * The distance in km until this component is due
     * @format float
     * @example 120000
     */
    due_in?: number;
    /**
     * The timestamp of the appointment
     * @format date-time
     * @example 2019-07-04T08:52:33.123Z
     */
    appointment_date?: string;
    /** The current status for the Proactive Maintenance Setting */
    proactive_maintenance_status: ProActiveMaintenanceStatus;
    /** If the component has dueAt in monthly precision. */
    hasMonthlyPrecision?: boolean;
    /**
     * The maintenance component measured at timestamp.
     * @format date-time
     * @example 2019-07-04T08:52:33.123Z
     */
    measuredAt?: string;
    /** True for maintenance component, false for custom component or others. */
    isMaintenanceComponent?: boolean;
}

export interface DiagnosticFillLevel {
    asset: Asset;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
    items: FillLevelMeasurements[];
}

export interface DiagnosticBrakeLining {
    asset: Asset;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
    items: BrakeLiningsAxle[];
}

export interface DiagnosticsSummary {
    asset: Asset;
    components_status: DiagnosticsMeasurementOverview;
    brake_linings_status: DiagnosticsMeasurementOverview;
    fill_levels_status: DiagnosticsMeasurementOverview;
    /**
     * An indicator that shows the availability and criticality of the battery data: Critical, Warning, Ok, Low_Mileage (no data now, but there will be in the future) and No_Data
     *
     */
    battery_status?: BatteryCriticality;
    /**
     * The timestamp of the earliest open appointment (upcoming or overdue) for this asset
     * @format date-time
     */
    next_appointment?: string;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
    /** The current status for the Proactive Maintenance Setting */
    proactive_maintenance_status: ProActiveMaintenanceStatus;
    engine_oil_status?: EngineOilStatus;
}

export interface DiagnosticsSummaryListResponse {
    items: DiagnosticsSummary[];
    cursors: Cursors;
    metadata: DiagnosticsSummaryMetadata;
}

export interface DiagnosticsSummaryMetadata {
    hasElectricVehicles: boolean;
}

export interface DiagnosticsMeasurementOverview {
    /**
     * Number of measurements with criticality danger.
     * @min 0
     */
    danger: number;
    /**
     * Number of measurements with criticality warning.
     * @min 0
     */
    warning: number;
    /**
     * Number of normal data components
     * @min 0
     */
    normal: number;
    /**
     * Number of unknown data components
     * @min 0
     */
    unknown: number;
}

export interface DiagnosticsComponentListResponse {
    items: DiagnosticComponent[];
    cursors: Cursors;
}

export interface DiagnosticsFillLevelListResponse {
    items: DiagnosticFillLevel[];
    cursors: Cursors;
}

export interface DiagnosticsBrakeLiningListResponse {
    items: DiagnosticBrakeLining[];
    cursors: Cursors;
}

export interface Cursors {
    /**
     * The `next` link pointing to the next result page including all sort and filter parameters.
     * @example https://my.service.cloud/resources?start_from=<next-position>&sort_by=...&sort_order=...
     */
    next?: string;
}

/**
 * Indicating the sort direction. Use this in combination with `sort_by`.
 * @example asc
 */
export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export interface Error {
    code: number;
    message: string;
}

/**
 * A complete component object for the diagnostics sidebar
 */
export type Components = MaintenanceItemUpdateProps & {
    items: ComponentItems[];
    /** The current status for the Proactive Maintenance Setting */
    proactive_maintenance_status: ProActiveMaintenanceStatus;
    overview: DiagnosticsMeasurementOverview;
};

export interface ComponentItems {
    id: string;
    /** The name of a (custom) component */
    name: string;
    /**
     * The date until this component is due
     * @format date-time
     * @example 2019-07-04T08:52:33.123Z
     */
    due_at?: string;
    /**
     * The distance in km until this component is due
     * @format float
     * @example 120000
     */
    due_in?: number;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
    /**
     * Indicating the type of a component. Note that custom_recurrent_component is a 'virtual' component type. It indicates a custom component with a recurrence so that users know if a specific component is recurrent or not.
     * @example custom_component
     */
    type: 'maintenance_component' | 'custom_component' | 'custom_recurrent_component';
    /** A flag indicading whether pro active maintenance should be turned on (or off) for all components and custom components. This flag is optional and can be `undefined`. If it is not set at all, the pro active maintenance settings are not changed. */
    proactive_maintenance_enabled: ProActiveMaintenanceFlag;
    /** a flag indicating if the due date for maintenance components points to a month intead of a specific date */
    hasMonthPrecision?: boolean;
    /**
     * A flag indicating if a component was fixed/maintained by the customer (probably based on a
     * call by the workshop & customer). In such a case the component is typically in state DUE or OVERDUE.
     *
     * @example false
     */
    solvedByCustomer?: boolean;
}

export interface QuickFeedback {
    /** Array of results quick feedback entries. Array must contain at least one entry. */
    result: QuickFeedbackResult[];
    /**
     * ISO language code
     * @example en-GB
     */
    locale: string;
    /**
     * The survey identifier to which the questions are related
     * @example notification-preference
     */
    surveyId: string;
}

export interface QuickFeedbackResult {
    /**
     * A question or a reference to a question like a translation key. The idea is that this identifier
     * is always the same, even if the translated question is changed in between.
     *
     * @example intl-msg:fleetstatus.overview.widget.feedback_widget.question_v1.question
     */
    question: string;
    /**
     * A predefined answer or a reference to a predefined answer the customer has selected. Only one
     * answer allowed per question.
     *
     * @example intl-msg:fleetstatus.overview.widget.feedback_widget.question_v1.options.other
     */
    answer: string;
    /**
     * Additional input given by the user if he/she does not select a predefined option in the form. Can
     * be used for free text answers.
     *
     * @example Notifications about component X
     */
    freetext?: string;
}

export interface Feedback {
    /**
     * rating from 1-7 provided by the user
     * @min 1
     * @max 7
     * @example 1
     */
    rating: number;
    /** free feedback text */
    text?: string;
    /** confirmation if the user is willing to be contacted in the future */
    contactable: boolean;
    /** feedback type */
    feedback_type: string;
}

/**
 * A complete fill level object for the diagnostics sidebar
 */
export type FillLevels = MaintenanceItemUpdateProps & {
    items: FillLevelMeasurements[];
    overview: DiagnosticsMeasurementOverview;
};

export interface FillLevelMeasurements {
    /** The type of fill level */
    type: FillLevelType;
    /** The origin of the engine oil values */
    signal?: SignalOrigin;
    /**
     * Fill level in percent
     * @example 45
     */
    percentage?: number;
    /**
     * Fill level in remaining range km
     * @example 3500
     */
    remaining_range?: number;
    level_engine_oil_state?: EngineOilStates;
    measured_at?: MeasuredAt;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
    /**
     * The SPN number of an active Engine Oil DTC
     * @format int32
     * @example 4550
     */
    engine_oil_dtc_spn?: number;
}

/**
 * The origin of the engine oil values
 * @example sensor_signal
 */
export type SignalOrigin = string;

/**
 * The type of fill level
 * @example fuel
 */
export enum FillLevelType {
    AdBlue = 'ad_blue',
    EngineOil = 'engine_oil',
    EngineOilV2 = 'engine_oil_v2',
    EngineOilDid = 'engine_oil_did',
    Fuel = 'fuel',
    WasherFluid = 'washer_fluid',
}

/**
 * A complete tire pressure object to be displayed in the sidebar
 */
export type TirePressures = MaintenanceItemUpdateProps & {
    axles: TirePressureAxle[];
};

export interface TirePressureAxle {
    /** The axle position as string to display in the FE, "1" is the front axle. Empty string e.g. for the spare tire */
    position: AxlePosition;
    items: {
        left_inner?: TirePressureMeasurement;
        left_outer?: TirePressureMeasurement;
        right_inner?: TirePressureMeasurement;
        right_outer?: TirePressureMeasurement;
    };
}

export interface TirePressureMeasurement {
    /**
     * The tire pressure in bar with one decimal digit
     * @format float
     * @example 4.5
     */
    pressure_bar?: number;
}

/**
 * The axle position as string to display in the FE, "1" is the front axle. Empty string e.g. for the spare tire
 * @example 1
 */
export enum AxlePosition {
    Type1 = '1',
    Type2 = '2',
    Type3 = '3',
    Type4 = '4',
    Type5 = '5',
    Type6 = '6',
    Type7 = '7',
    Type8 = '8',
    Type9 = '9',
    Type10 = '10',
    Type11 = '11',
    Type12 = '12',
    Type13 = '13',
    ReserveWheels = 'reserve_wheels',
}

/**
 * A complete brake lining object to be displayed in the sidebar
 */
export type BrakeLinings = MaintenanceItemUpdateProps & {
    axles: BrakeLiningsAxle[];
    overview: DiagnosticsMeasurementOverview;
};

export interface BrakeLiningsAxle {
    /** The axle position as string to display in the FE, "1" is the front axle. Empty string e.g. for the spare tire */
    position: AxlePosition;
    items: {
        left?: BrakeLiningsMeasurement;
        right?: BrakeLiningsMeasurement;
    };
}

export interface BrakeLiningsMeasurement {
    /**
     * The percentage of brake linings
     * @example 20
     */
    percentage?: number;
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
}

/**
 * An array of appointments with details; ordered by date (ascending). Only appointments with components or damages attached are returned.
 */
export type Appointments = Appointment[];

/**
 * An object with all relevant information of an appointment for the diagnostics sidebar.
 */
export interface Appointment {
    /**
     * The timestamp of the appointment
     * @format date-time
     */
    date: string;
    workshop?: Workshop;
    /** The tasks that will be done during the appointment. Can be a component or a damage. This array can not be empty (appointments without these items are filtered in the backend). */
    items: {
        id: string;
        /** Name of a component or description of a damage */
        name: string;
        /**
         * Indicating the type of the task (custom_component, maintenance_component, custom_recurrent_component, damage). Note that custom_recurrent_component is a 'virtual' component type. It indicates a custom component with a recurrence so that users know if a specific component is recurrent or not.
         * @example custom_component
         */
        type: 'maintenance_component' | 'custom_component' | 'custom_recurrent_component' | 'damage';
        /**
         * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
         * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
         *
         * Here is the mapping table:
         * <hr/>
         * <table>
         *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
         *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
         *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
         *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
         *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
         *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
         *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
         *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
         *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
         *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
         *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
         *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
         *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
         *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
         *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
         *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
         * </table>
         * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
         */
        criticality: Criticality;
        /**
         * The date to display for this item (due_at date for components, occurred_at_date for damages).
         * @format date-time
         * @example 2019-07-04T08:52:33.123Z
         */
        date?: string;
        /**
         * The distance in km until this component is due. Only for components; not for damages.
         * @example 120000
         */
        mileage?: number;
        /** a flag indicating if the due date for maintenance components points to a month intead of a specific date */
        hasMonthPrecision?: boolean;
    }[];
}

/**
* An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
<span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>

Here is the mapping table:
<hr/>
<table>
  <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
  <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
  <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
  <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
  <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
  <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
  <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
  <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
  <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
  <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
  <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
  <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
  <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
  <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
  <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
  <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
</table>
<hr/>
<b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
*/
export enum Criticality {
    Danger = 'danger',
    Warning = 'warning',
    Normal = 'normal',
    Unknown = 'unknown',
}

/**
 * An indicator that shows the availability and criticality of the battery data: Critical, Warning, Ok, Low_Mileage (no data now, but there will be in the future) and No_Data
 */
export enum BatteryCriticality {
    CRITICAL = 'CRITICAL',
    WARNING = 'WARNING',
    OK = 'OK',
    LOW_MILEAGE = 'LOW_MILEAGE',
    NO_DATA = 'NO_DATA',
}

export interface MaintenanceItemUpdateProps {
    /**
     * The date when a measurement happened
     * @format date-time
     * @example 2019-07-04T08:52:33.123Z
     */
    measured_at_date?: string;
    /**
     * The current distance travelled in km when a measurement happened
     * @format float
     * @example 120000
     */
    measured_at_distance?: number;
}

/**
 * Defines the product that is booked for this asset or null/undefined if no service product is booked for this asset.
 * @example service_care_s
 */
export enum Product {
    ServiceCareS = 'service_care_s',
    ServiceCareM = 'service_care_m',
    ServiceCareL = 'service_care_l',
}

export interface Pricing {
    /**
     * SKU without levels
     * @example MAN-SKU00000049
     */
    id: string;
    products: PricingProducts;
    /**
     * Defines the currency of the price
     * @example EUR
     */
    currency: 'EUR';
}

export interface PricingProducts {
    service_care_s?: ProductPricing;
    service_care_m?: ProductPricing;
    service_care_l?: ProductPricing;
}

export interface ProductPricing {
    /**
     * Level according to product
     * @example 20
     */
    level?: string;
    /**
     * Price of the product per day
     * @format float
     * @example 0.5
     */
    price: number;
    bookable: boolean;
}

export interface WorkshopContactPerson {
    /** @format uuid */
    id: string;
    /**
     * The name of the contact person in the format "$firstname $lastname".
     * @example George Letton
     */
    name?: string;
    /** The phone numbers of the contact person. */
    phone_numbers?: PhoneNumber[];
    /**
     * The email of the contact person.
     * @example george.letton@man.eu
     */
    email?: string;
}

export interface AssignedWorkshop {
    /** @example AT0134 */
    id: string;
    /**
     * The name of the assigned workshop.
     * @example MAN Truck & Bus Service Muenchen
     */
    name: string;
    /**
     * The city where the workshop is located at.
     * @example Muenchen
     */
    city?: string;
    /**
     * The street where the worskhop is located at.
     * @example Am Kirchenholzl 1
     */
    street?: string;
    /**
     * The country where the workshop is located at, translated in the user's locale.
     * @example Deutschland
     */
    country: string;
    /**
     * Alpha-2 country code
     * @example AT
     */
    country_code: string;
    /**
     * The phone number of the workshop
     * @example 089 / 12 34 56 78
     */
    phone_number?: string;
}

export interface AssetDetail {
    asset: Asset;
    latest_events?: MaintenanceItemUpdateProps;
    assigned_workshop?: AssignedWorkshop;
    /** A generic contact person used to display all contact person types in the sidebar. */
    workshop_contact_person?: SidebarContactPerson;
    /** One or two uptime contact person(s) for service care product L only. Other product types have zero uptime contact persons. */
    uptime_contact_persons?: UptimeSidebarContactPersonList;
    /** The current status for the Proactive Maintenance Setting */
    proactive_maintenance_status?: ProActiveMaintenanceStatus;
}

export interface AssetGeneralData {
    series?: string;
    /** @format date */
    purchase_date?: string;
    /** @format date */
    register_date?: string;
    emission_class?: string;
}

export interface Warranty {
    text: string;
    /** @format date */
    start_date?: string;
    /** @format date */
    end_date?: string;
    mileage_from?: number;
    mileage_to?: number;
}

export interface ServiceContract {
    text: string;
    /** @format date */
    start_date?: string;
    /** @format date */
    end_date?: string;
}

export interface ContractAndWarrantyLinks {
    service_contract_url?: string;
    warranty_url?: string;
}

export interface Workshop {
    /** @example AT0134 */
    id: string;
    /** @example Serviceniederlassung Bad Fischau */
    name: string;
    /** @example MAN Straße 1, 2721 Bad Fischau */
    address: string;
    /** @example München */
    city?: string;
    /** @example DE */
    country_code: string;
}

export interface WorkshopForAssignment {
    workshop: Workshop;
    supportedAssetTypes: AssetType[];
}

export interface WorkshopAssignments {
    /** The id of the workshop to which assets should be assigned. */
    workshop_id: string;
    /** The id of the user who is the contact person. */
    contact_person_user_id: string;
    /** The list of phone numbers to contact the user. */
    phone_numbers: PhoneNumber[];
    asset_ids: AssetIdList;
    /** A flag indicading whether pro active maintenance should be turned on (or off) for all components and custom components. This flag is optional and can be `undefined`. If it is not set at all, the pro active maintenance settings are not changed. */
    proactive_maintenance_enabled?: ProActiveMaintenanceFlag;
}

export interface WorkshopCountriesResponse {
    items: WorkshopCountry[];
}

export interface WorkshopCountry {
    /** @example Germany */
    country: string;
    /**
     * Alpha-2 country code
     * @example DE
     */
    country_code: string;
    /** @example true */
    is_default: boolean;
}

export interface WorkshopsResponse {
    items: WorkshopForAssignment[];
}

export interface PhoneNumber {
    /**
     * The type of phone number
     * @example PHONE_TYPE_MOBILE
     */
    type: 'PHONE_TYPE_LAND_LINE' | 'PHONE_TYPE_MOBILE';
    /** @example 351911231212 */
    number: string;
}

/**
 * A flag indicading whether pro active maintenance should be turned on (or off) for all components and custom components. This flag is optional and can be `undefined`. If it is not set at all, the pro active maintenance settings are not changed.
 */
export type ProActiveMaintenanceFlag = boolean;

export interface ContactPerson {
    userId: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    email: string;
    /** for uptime contact person, a maximum of two phone numbers is allowed. */
    phoneNumbers: PhoneNumber[];
}

/**
 * One or two uptime contact person(s) for service care product L only. Other product types have zero uptime contact persons.
 */
export type UptimeSidebarContactPersonList = SidebarContactPerson[];

/**
 * A generic contact person used to display all contact person types in the sidebar.
 */
export interface SidebarContactPerson {
    /**
     * Id of the contact person displayed on the sidebars.
     * @format uuid
     */
    id: string;
    /**
     * The name of the contact person displayed in the sidebars in the format "$firstname $lastname".
     * @example George Letton
     */
    name: string;
    /** The phone numbers of the contact person displayed on the sidebars, for uptime contact persons, only two phone numbers are allowed. */
    phone_numbers: PhoneNumber[];
    /**
     * The email of the contact person displayed on the sidebars.
     * @example george.letton@man.eu
     */
    email?: string;
}

export interface UptimeContactPersonAssignment {
    asset_ids: AssetIdList;
    /** One or two uptime contact person(s) for service care product L only. Other product types have zero uptime contact persons. */
    uptime_contact_persons: UptimeContactPersonList;
}

/**
 * One or two uptime contact person(s) for service care product L only. Other product types have zero uptime contact persons.
 */
export type UptimeContactPersonList = UptimeContactPerson[];

export interface UptimeContactPerson {
    /** Id of the uptime contact person. */
    id: string;
    /** The phone numbers of the uptime contact person. */
    phone_numbers: PhoneNumber[];
}

export interface ManagedUser {
    userId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phoneNumber?: string;
    /**
     * the language name of the user's language, translated in the language of the user which does the request
     * @example Deutsch
     */
    language: string;
    /**
     * the locale of the user's language
     * @example en-GB
     */
    locale: string;
}

export interface ManagedUsersList {
    items: ManagedUser[];
}

export interface ProActiveMaintenanceSettings {
    maintenance_components: ProActiveMaintenanceComponent[];
    custom_components: boolean;
}

export interface ProActiveMaintenanceSettingsMultiple {
    asset_ids: AssetIdList;
    maintenance_components: boolean;
    custom_components: boolean;
}

export interface ProActiveMaintenanceComponent {
    type: string;
    name: string;
    proactive_maintenance_enabled: boolean;
}

/**
 * @example ok
 */
export enum EngineOilStates {
    CriticallyLow = 'critically_low',
    Low = 'low',
    Ok = 'ok',
    LevelMaximum = 'level_maximum',
    CriticallyOverfilled = 'critically_overfilled',
    TooHigh = 'too_high',
}

export type Status = Criticality;

export interface EngineOilStatus {
    status: Status;
    /** The origin of the engine oil values */
    signal: SignalOrigin;
    engine_oil_state?: EngineOilStates;
    measured_at?: MeasuredAt;
    /**
     * The SPN number of an active Engine Oil DTC
     * @format int32
     * @example 4550
     */
    engine_oil_dtc_spn?: number;
}

/**
 * The current status for the Proactive Maintenance Setting
 */
export enum ProActiveMaintenanceStatus {
    On = 'on',
    PartiallyOn = 'partially_on',
    Off = 'off',
}

export interface UptimeWidgetsList {
    items: UptimeWidget[];
}

/**
 * The overall information for the Uptime widget, including job_id, asset, status and dtcs with components attached
 */
export interface UptimeWidget {
    /** The ticket id */
    ticket_id: string;
    /**
     * Unique id of service care ticket managed by Syntia
     * @format int32
     * @example 1234567
     */
    job_id?: string;
    /**
     * Unique id of service care ticket managed by Syntia
     * @format int32
     * @example 1234567
     */
    mobile24_id?: number;
    asset: Asset;
    /**
     * A enum with the status for a Syntia/DTC ticket * JOB_CREATED - Service Care ticket created * SERVICECARE_REOPEN - Ticked reopened * SERVICECARE_CUSTOMER_CALLED - Agent calls customer * SERVICECARE_CUSTOMER_CONTACT_NOT_AVAILABLE - Contact not available * SERVICECARE_CUSTOMER_CONTACT_NOT_REACHED - Contact not reached 3 times --> same action as e-mail sending * SERVICECARE_CUSTOMER_NEEDS_TIME_TO_DECIDE - Customer needs time do decide * SERVICECARE_CUSTOMER_NEEDS_TIME_TO_DECIDE_CLOSED - Customer didn't decide on an action in 14 days * SERVICECARE_CUSTOMER_DONT_WANT_HELP - Customer decision "Don't want help" * SERVICECARE_CUSTOMER_TAKE_CARE_ON_HIS_OWN - Customer decision "Take care for breakdown on his own" * SERVICECARE_CUSTOMER_TOW_ON_HIS_OWN - Customer decision "Tow vehicle to workshop on his own" * MOBILE24_CREATED - Customer decision "Breakdown service required"; Customer said "yes" * MOBILE24_WORKSHOP_ORDER_SENT_OUT - 1st Workshop order sent out (Werkstatt wurde beauftragt) * MOBILE24_WORKSHOP_ETA - Order ETA * MOBILE24_WORKSHOP_DTA - Arrived at destination (Definite Time of Arrival) * MOBILE24_WORKSHOP_EDR - Estimated Date of Repair * MOBILE24_WORKSHOP_DER - Definite end of repair * MOBILE24_DER - Definite end of repair by workshop(s) * MOBILE24_CLOSED - Ticket closed (deprecated)
     *
     */
    status: StatusType;
    /**
     * The timestamp for the status given by the 'status' parameter.
     * @format date-time
     */
    status_timestamp: string;
    /**
     * The date stating when this ticket/job was initially opened.
     * @format date-time
     */
    created_at: string;
    /**
     * The date stating when this ticket/job was finished.
     * @format date-time
     */
    closed_at?: string;
    /** A boolean stating if this is a recent ticket. */
    is_new: boolean;
    /** The Workshop name that handles this ticket */
    workshop_name?: string;
    /** List of Dtcs that are reported by the truck that include customer information */
    dtcs: Dtc[];
    /** The current position of the asset assigned to the ticket */
    current_asset_position?: AssetPosition;
}

/**
 * A enum with the status for a Syntia/DTC ticket * JOB_CREATED - Service Care ticket created * SERVICECARE_REOPEN - Ticked reopened * SERVICECARE_CUSTOMER_CALLED - Agent calls customer * SERVICECARE_CUSTOMER_CONTACT_NOT_AVAILABLE - Contact not available * SERVICECARE_CUSTOMER_CONTACT_NOT_REACHED - Contact not reached 3 times --> same action as e-mail sending * SERVICECARE_CUSTOMER_NEEDS_TIME_TO_DECIDE - Customer needs time do decide * SERVICECARE_CUSTOMER_NEEDS_TIME_TO_DECIDE_CLOSED - Customer didn't decide on an action in 14 days * SERVICECARE_CUSTOMER_DONT_WANT_HELP - Customer decision "Don't want help" * SERVICECARE_CUSTOMER_TAKE_CARE_ON_HIS_OWN - Customer decision "Take care for breakdown on his own" * SERVICECARE_CUSTOMER_TOW_ON_HIS_OWN - Customer decision "Tow vehicle to workshop on his own" * MOBILE24_CREATED - Customer decision "Breakdown service required"; Customer said "yes" * MOBILE24_WORKSHOP_ORDER_SENT_OUT - 1st Workshop order sent out (Werkstatt wurde beauftragt) * MOBILE24_WORKSHOP_ETA - Order ETA * MOBILE24_WORKSHOP_DTA - Arrived at destination (Definite Time of Arrival) * MOBILE24_WORKSHOP_EDR - Estimated Date of Repair * MOBILE24_WORKSHOP_DER - Definite end of repair * MOBILE24_DER - Definite end of repair by workshop(s) * MOBILE24_CLOSED - Ticket closed (deprecated)
 * @example JOB_CREATED
 */
export type StatusType = string;

export interface Dtc {
    /** ECU full name */
    ecu?: string;
    /**
     * SPN number
     * @format int32
     */
    spn?: number;
    /** Customer description */
    description?: string | null;
    /** Customer recommendation */
    recommendation?: string | null;
    /** Customer component */
    component?: string | null;
    /** Failure Mode Identification description */
    fmi_description?: string | null;
    /** Questions to customer */
    question?: string | null;
    /**
     * The date when this DTC occured at
     * @format date-time
     */
    occurrence_date?: string;
    /**
     * The date when this DTC was transmitted
     * @format date-time
     */
    send_timestamp?: string;
}

/**
 * The current position of the asset assigned to the ticket
 */
export interface AssetPosition {
    /**
     * Longitude in degrees
     * @format float
     */
    longitude?: number;
    /**
     * Latitude in degrees
     * @format float
     */
    latitude?: number;
    /**
     * Timestamp of the received position
     * @format date-time
     */
    timestamp?: string;
}

/**
 * Types of supported workshop businesses
 * @example truck
 */
export enum AssetType {
    Truck = 'truck',
    Van = 'van',
    Trailer = 'trailer',
    Bus = 'bus',
}

export interface MaintenanceWidgetsList {
    widgets: MaintenanceWidget[];
}

export interface MaintenanceWidget {
    widget_type: WidgetTypeEnum;
}

export enum WidgetTypeEnum {
    Counter = 'counter',
    Pie = 'pie',
    List = 'list',
}

export type CounterWidget = MaintenanceWidget & {
    /** Defines the maintenance widget id */
    id: MaintenanceWidgetId;
    /** Counter widget content data */
    data: CounterWidgetData;
};

/**
 * Counter widget content data
 */
export interface CounterWidgetData {
    /**
     * An overall indicator that shows the criticality for the item. It's used in the frontend to format the items with the colors
     * <span style="color:red">danger</span>,<span style="color:orange">warning</span>, <span style="color:black">normal</span>
     *
     * Here is the mapping table:
     * <hr/>
     * <table>
     *   <tr><th>Entity</th><th>Source Value</th><th>Mapped Value</th></tr>
     *   <tr><td>(custom) component</td><td>overdue</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>(custom) component</td><td>almost_due</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>(custom) component</td><td>next</td><td>normal</td></tr>
     *   <tr><td>(custom) component</td><td>not_initialised</td><td>normal</td></tr>
     *   <tr><td>damage</td><td>critical (alert level)</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>damage</td><td>warning (alert level)</td><td><span style="color:orange">warning</span></td></tr>
     *   <tr><td>fill level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>fill level</td><td>normal</td><td>normal</td></tr>
     *   <tr><td>brake linings percentage</td><td>0..20</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>brake linings percentage</td><td>21..30</td><td> <span style="color:orange">warning</span></td></tr>
     *   <tr><td>brake linging percentage</td><td>31..100</td><td>normal</td></tr>
     *   <tr><td>engine oil level</td><td>error</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>low</td><td> <span style="color:red">danger</span></td></tr>
     *   <tr><td>engine oil level</td><td>normal</td><td>normal</td></tr>
     * </table>
     * <b>The enum values are defined here in descending order, to enable sorting in the backend by ordinal value (0 = danger, 1 = warning, 2 = normal)</b>
     */
    criticality: Criticality;
    /** @format int32 */
    mainValue: number;
    details: CounterWidgetDetails[];
}

/**
 * The details in the widget footer
 */
export interface CounterWidgetDetails {
    /** Defines the maintenance widget details id */
    detailId: CounterWidgetDetailId;
    criticalities: Criticality[];
    /** @format int32 */
    value: number;
}

export type PieChartWidget = MaintenanceWidget & {
    /** Defines the maintenance widget id */
    id: MaintenanceWidgetId;
    data: PieChartWidgetData;
};

export interface PieChartWidgetData {
    /** @format int32 */
    centerValue: number;
    entries: {
        /**
         * The id of an pie chart item. All items that belong to the same chart have the same prefix.
         *
         * ### Enums as Translation Strings
         * Please be aware that those enum keys are used to generate translation strings for the FE. You must ensure,
         * that the following two keys exist when introducing and using new enum values:
         * ```json
         * "intl-msg:fleetstatus.overview.widget.pie_widget.fleet_booking.<enum_key>",
         * "intl-msg:fleetstatus.overview.widget.pie_widget.fleet_booking.<enum_key>.condensed",
         * ```
         * #### Example
         * For `fleet_booking_no_booking`, the following two keys must exist:
         * "intl-msg:fleetstatus.overview.widget.pie_widget.fleet_booking.fleet_booking_no_booking",
         * "intl-msg:fleetstatus.overview.widget.pie_widget.fleet_booking.fleet_booking_no_booking.condensed",
         */
        id: PieChartWidgetItemId;
        /**
         * The value of a pie element
         * @format int32
         * @example 35
         */
        value: number;
    }[];
}

/**
 * Defines the maintenance widget id
 */
export enum MaintenanceWidgetId {
    VehicleWithMaintenanceToSolve = 'vehicle_with_maintenance_to_solve',
    VehicleWithDamagesToSolve = 'vehicle_with_damages_to_solve',
    VehicleWithAppointment = 'vehicle_with_appointment',
    FleetBooking = 'fleet_booking',
    VehicleStatus = 'vehicle_status',
}

/**
 * Defines the maintenance widget details id
 */
export enum CounterWidgetDetailId {
    MaintainComponents = 'maintain_components',
    BrakeLinings = 'brake_linings',
    EngineOilFillLevels = 'engine_oil_fill_levels',
    FillLevels = 'fill_levels',
    CriticalDamages = 'critical_damages',
    AppointmentsThisWeek = 'appointments_this_week',
}

/**
* The id of an pie chart item. All items that belong to the same chart have the same prefix.

### Enums as Translation Strings
Please be aware that those enum keys are used to generate translation strings for the FE. You must ensure,
that the following two keys exist when introducing and using new enum values:

```json
"intl-msg:fleetstatus.overview.widget.pie_widget.fleet_booking.<enum_key>",
"intl-msg:fleetstatus.overview.widget.pie_widget.fleet_booking.<enum_key>.condensed",
```
#### Example

For `fleet_booking_no_booking`, the following two keys must exist:

```
"intl-msg:fleetstatus.overview.widget.pie_widget.fleet_booking.fleet_booking_no_booking",
"intl-msg:fleetstatus.overview.widget.pie_widget.fleet_booking.fleet_booking_no_booking.condensed",
```
*/
export enum PieChartWidgetItemId {
    FleetBookingServiceCareS = 'fleet_booking_service_care_s',
    FleetBookingServiceCareM = 'fleet_booking_service_care_m',
    FleetBookingNoBooking = 'fleet_booking_no_booking',
}

export type ListWidget = MaintenanceWidget & {
    /** Defines the maintenance widget id */
    id: MaintenanceWidgetId;
    data: ListWidgetData;
};

export type ListWidgetData = ListWidgetItem[];

export interface ListWidgetItem {
    asset: Asset;
    criticalities?: Criticality[];
    listItemMessage: ListItemMessage;
}

export interface ListItemMessage {
    itemMessageType?: ListWidgetItemMessageTypeEnum;
}

export enum ListWidgetItemMessageTypeEnum {
    Appointment = 'appointment',
    WaitingForMaintenance = 'waiting_for_maintenance',
    WaitingForSelfMaintenance = 'waiting_for_self_maintenance',
}

export type ListWidgetItemAppointment = (ListItemMessage & object) & {
    /** @format date-time */
    nextAppointmentDate?: string;
    overdueComponentsMissingInAppointments?: number;
    excludedMaintenanceTypes?: MaintenanceType[];
};

export type ListWidgetItemWaitingForMaintenance = (ListItemMessage & object) & {
    excludedMaintenanceTypes?: MaintenanceType[];
};

export type ListWidgetItemWaitingForSelfMaintenance = ListItemMessage;

/**
 * The different content type of a maintenance.
 */
export enum MaintenanceType {
    MaintenanceComponents = 'maintenance_components',
    CustomComponents = 'custom_components',
    BrakeLinings = 'brake_linings',
    FillLevels = 'fill_levels',
}

/**
 * The variant of vehicle
 */
export enum VehicleVariant {
    ICAN = 'ICAN',
    FMS1 = 'FMS1',
    FMS2 = 'FMS2',
    FMS3 = 'FMS3',
    FMS4 = 'FMS4',
    NHMR = 'NHMR',
    VW = 'VW',
    E4C = 'E4C',
    TRUE = 'TRUE',
    ETGE = 'ETGE',
}

/**
 * HomeWorkshop
 */
export interface HomeWorkshop {
    accountId: string;
    hasHomeWorkshop: boolean;
    workshop?: Workshop;
    contactPerson?: ContactPerson;
}

/**
 * HomeWorkshopAssignment
 */
export interface HomeWorkshopAssignment {
    workshopId: string;
    contactUserId: string;
    phoneNumbers: PhoneNumber[];
}

export declare namespace Assets {
    /**
     * No description
     * @tags assets
     * @name GetAssets
     * @summary List all assets of a customer fleet.
     * @request GET:/assets
     * @response `200` `AssetSummaryListResponse` A paged array of assets.
     * @response `default` `Error` unexpected error
     */
    export namespace GetAssets {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /** Search string which filters the results. */
            q?: string;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?:
                | 'asset_name'
                | 'asset_vin'
                | 'asset_manufacturer'
                | 'asset_type'
                | 'workshop_contact_person_name'
                | 'assigned_workshop_name'
                | 'transfer_status'
                | 'product'
                | 'default_order';
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssetSummaryListResponse;
    }
    /**
     * No description
     * @tags assets
     * @name GetAssetById
     * @summary Get an enhanced asset information for displaying in the sidebar.
     * @request GET:/assets/{asset_id}
     * @response `200` `AssetDetail` An asset detail response
     * @response `default` `Error` unexpected error
     */
    export namespace GetAssetById {
        export type RequestParams = {
            /** @format uuid */
            assetId: string;
        };
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssetDetail;
    }
    /**
     * No description
     * @tags assets
     * @name GetAssetIdDetailsExtension
     * @summary Get more truck data, contracts and warranties.
     * @request GET:/assets/{asset_id}/details-extension
     * @response `200` `AssetDetailExtension` Object containing more truck data, contracts and warranties.
     */
    export namespace GetAssetIdDetailsExtension {
        export type RequestParams = {
            /** @format uuid */
            assetId: string;
        };
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssetDetailExtension;
    }
}

export declare namespace CustomComponents {
    /**
     * No description
     * @tags custom-components
     * @name CreateCustomComponent
     * @summary Creates a custom component
     * @request POST:/custom-components
     * @response `201` `CustomComponent` Custom component has been created
     * @response `default` `Error` unexpected error
     */
    export namespace CreateCustomComponent {
        export type RequestParams = {};
        export type RequestQuery = {
            /** @example DATE */
            type: 'date' | 'distance';
        };
        export type RequestBody = CustomComponent;
        export type RequestHeaders = {};
        export type ResponseBody = CustomComponent;
    }
    /**
     * No description
     * @tags custom-components
     * @name GetCustomComponentById
     * @summary Get a single custom component by id.
     * @request GET:/custom-components/{id}
     * @response `200` `CustomComponent` A custom component.
     * @response `default` `Error` unexpected error
     */
    export namespace GetCustomComponentById {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CustomComponent;
    }
    /**
     * No description
     * @tags custom-components
     * @name UpdateCustomComponentById
     * @summary Updates a custom component
     * @request PUT:/custom-components/{id}
     * @response `200` `CustomComponent` Custom component has been updated
     * @response `default` `Error` unexpected error
     */
    export namespace UpdateCustomComponentById {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {
            /** @example DATE */
            type: 'date' | 'distance';
        };
        export type RequestBody = CustomComponent;
        export type RequestHeaders = {};
        export type ResponseBody = CustomComponent;
    }
    /**
     * No description
     * @tags custom-components
     * @name DeleteCustomComponentById
     * @summary Delete a single custom component by id.
     * @request DELETE:/custom-components/{id}
     * @response `204` `void` Custom component has been deleted
     * @response `404` `void` Custom component could not be found
     * @response `default` `Error` unexpected error
     */
    export namespace DeleteCustomComponentById {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }
}

export declare namespace Damages {
    /**
     * No description
     * @name SummaryDamages
     * @request SUMMARY:/damages
     */
    export namespace SummaryDamages {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /** Search string which filters the results. */
            q?: string;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'asset_name' | 'driver_name' | 'status' | 'description' | 'creation_date' | 'attachment_count' | 'appointment';
            /** An array with processing states for filtering the results */
            processing_states?: DamageProcessingState[];
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }
    /**
     * No description
     * @tags damages
     * @name GetDamages
     * @request GET:/damages
     * @response `200` `DamageListResponse` A paged array of damages.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDamages {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /** Search string which filters the results. */
            q?: string;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'asset_name' | 'driver_name' | 'status' | 'description' | 'creation_date' | 'attachment_count' | 'appointment';
            /** An array with processing states for filtering the results */
            processing_states?: DamageProcessingState[];
            /** Ids of the assets for which the damages should be fetched. Leave off this parameter to fetch damages for all available assets. */
            asset_ids?: AssetIdList;
            /** Ids of the drivers for which the damages should be fetched (i.e. damages reported by these drivers). Leave off this parameter to fetch damages for all available assets. */
            driver_ids?: DriverIdList;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DamageListResponse;
    }
    /**
     * @description Note that this POST endpoint does not create or modify data, it just serves as an alternative for the GET endpoint with assetIds included in the body rather than the URL. While it's a bit unorthodox, this helps avoid situations where the GET-URL becomes too long for the browser to dispatch the request, particularly in IE.
     * @tags damages
     * @name GetDamagesInfo
     * @request POST:/damages
     * @response `200` `DamageListResponse` A paged array of damages.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDamagesInfo {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /** Search string which filters the results. */
            q?: string;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'asset_name' | 'driver_name' | 'status' | 'description' | 'creation_date' | 'attachment_count' | 'appointment';
            /** An array with processing states for filtering the results */
            processing_states?: DamageProcessingState[];
        };
        export type RequestBody = DamageListRequestBody;
        export type RequestHeaders = {};
        export type ResponseBody = DamageListResponse;
    }
    /**
     * No description
     * @tags damages
     * @name GetDamageById
     * @summary Get an enhanced damage information for displaying in the sidebar.
     * @request GET:/damages/{id}
     * @response `200` `DamageDetail` A damage detail response.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDamageById {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DamageDetail;
    }
}

export declare namespace Diagnostics {
    /**
     * No description
     * @name SummaryDiagnostics
     * @request SUMMARY:/diagnostics
     */
    export namespace SummaryDiagnostics {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'asset_name' | 'components_status' | 'brake_linings' | 'engine_oil_level' | 'fill_levels' | 'batteries' | 'next_appointment' | 'total_critical_items';
            /** An array with proactive_maintenance_status for filtering the results. If no values are provided, this filter will be ignored. */
            proactive_maintenance_status?: ProActiveMaintenanceStatus[];
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }
    /**
     * No description
     * @tags diagnostics
     * @name GetDiagnostics
     * @request GET:/diagnostics
     * @response `200` `DiagnosticsSummaryListResponse` A paged array of assets with diagnostic overview.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDiagnostics {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'asset_name' | 'components_status' | 'brake_linings' | 'engine_oil_level' | 'fill_levels' | 'batteries' | 'next_appointment' | 'total_critical_items';
            /** An array with proactive_maintenance_status for filtering the results. If no values are provided, this filter will be ignored. */
            proactive_maintenance_status?: ProActiveMaintenanceStatus[];
            /** Ids of the assets for which the damages should be fetched. Leave off this parameter to fetch damages for all available assets. */
            asset_ids?: AssetIdList;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticsSummaryListResponse;
    }
    /**
     * @description Note that this POST endpoint does not create or modify data, it just serves as an alternative for the GET endpoint with assetIds included in the body rather than the URL. While it's a bit unorthodox, this helps avoid situations where the GET-URL becomes too long for the browser to dispatch the request, particularly in IE.
     * @tags diagnostics
     * @name GetDiagnosticsInfo
     * @request POST:/diagnostics
     * @response `200` `DiagnosticsSummaryListResponse` A paged array of assets with diagnostic overview.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDiagnosticsInfo {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'asset_name' | 'components_status' | 'brake_linings' | 'engine_oil_level' | 'fill_levels' | 'batteries' | 'next_appointment' | 'total_critical_items';
            /** An array with proactive_maintenance_status for filtering the results. If no values are provided, this filter will be ignored. */
            proactive_maintenance_status?: ProActiveMaintenanceStatus[];
        };
        export type RequestBody = DiagnosticsListRequestBody;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticsSummaryListResponse;
    }
    /**
     * No description
     * @name SummaryDiagnostics2
     * @request SUMMARY:/diagnostics/export
     * @originalName summaryDiagnostics
     * @duplicate
     */
    export namespace SummaryDiagnostics2 {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /** An array with the type of data which should be exported */
            content?: DiagnosticsExportContentItem[];
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }
    /**
     * No description
     * @tags diagnostics
     * @name GetDiagnosticsExport
     * @request GET:/diagnostics/export
     * @response `200` `File` An XLSX file with all selected data.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDiagnosticsExport {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /** An array with the type of data which should be exported */
            content?: DiagnosticsExportContentItem[];
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = File;
    }
    /**
     * @description Fetches a diagnostics entry to be displayed in the sidebar
     * @tags diagnostics
     * @name GetDiagnosticById
     * @request GET:/diagnostics/{id}
     * @response `200` `DiagnosticDetail` A diagnostic detail entry
     * @response `default` `Error` unexpected error
     */
    export namespace GetDiagnosticById {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticDetail;
    }
    /**
     * No description
     * @name SummaryDiagnostics3
     * @request SUMMARY:/diagnostics/electric-assets
     * @originalName summaryDiagnostics
     * @duplicate
     */
    export namespace SummaryDiagnostics3 {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }
    /**
     * @description This endpoint checks whether the current context or session has associated electric assets. Returns `true` if electric assets are present and `false` otherwise.
     * @tags diagnostics
     * @name HasElectricAssets
     * @request GET:/diagnostics/electric-assets
     * @response `200` `ProActiveMaintenanceFlag` Success
     * @response `default` `Error` unexpected error
     */
    export namespace HasElectricAssets {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ProActiveMaintenanceFlag;
    }
    /**
     * No description
     * @name SummaryDiagnostics4
     * @request SUMMARY:/diagnostics/components
     * @originalName summaryDiagnostics
     * @duplicate
     */
    export namespace SummaryDiagnostics4 {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }
    /**
     * @description Lists just the components for all assets
     * @tags diagnostics
     * @name GetDiagnosticsComponents
     * @request GET:/diagnostics/components
     * @response `200` `DiagnosticsComponentListResponse` A paged array of components diagnostics for the assets.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDiagnosticsComponents {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /** Search string which filters the results. */
            q?: string;
            criticality?: Criticality[];
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'criticality' | 'asset_name' | 'proactive_maintenance_status' | 'component' | 'due_in' | 'due_at' | 'appointment_at';
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticsComponentListResponse;
    }
    /**
     * @description Lists just the components for a set of assets. Note that this POST endpoint does not create or modify data, it just serves as an alternative for the GET endpoint with assetIds included in the body rather than the URL. While it's a bit unorthodox, this helps avoid situations where the GET-URL becomes too long for the browser to dispatch the request, particularly in IE.
     * @tags diagnostics
     * @name PostDiagnosticsComponents
     * @request POST:/diagnostics/components
     * @response `200` `DiagnosticsComponentListResponse` A paged array of components diagnostics for the assets.
     * @response `default` `Error` unexpected error
     */
    export namespace PostDiagnosticsComponents {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            /** Search string which filters the results. */
            q?: string;
            criticality?: Criticality[];
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'criticality' | 'asset_name' | 'proactive_maintenance_status' | 'component' | 'due_in' | 'due_at' | 'appointment_at';
        };
        export type RequestBody = DiagnosticsListRequestBody;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticsComponentListResponse;
    }
    /**
     * No description
     * @name SummaryDiagnostics5
     * @request SUMMARY:/diagnostics/internal-fill-levels
     * @originalName summaryDiagnostics
     * @duplicate
     */
    export namespace SummaryDiagnostics5 {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }
    /**
     * @description Lists just the fill levels for all assets considering engine oil from DIDs
     * @tags diagnostics
     * @name GetDiagnosticsInternalFillLevels
     * @request GET:/diagnostics/internal-fill-levels
     * @response `200` `DiagnosticsFillLevelListResponse` A paged array of fill level diagnostics for the assets.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDiagnosticsInternalFillLevels {
        export type RequestParams = {};
        export type RequestQuery = {
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            criticality?: Criticality[];
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'criticality' | 'asset_name' | 'engine_oil' | 'ad_blue' | 'fuel' | 'washer_fluid';
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticsFillLevelListResponse;
    }
    /**
     * @description Lists just the fill levels for a set of assets, considering engine oil from DIDs Note that this POST endpoint does not create or modify data, it just serves as an alternative for the GET endpoint with assetIds included in the body rather than the URL. While it's a bit unorthodox, this helps avoid situations where the GET-URL becomes too long for the browser to dispatch the request, particularly in IE.
     * @tags diagnostics
     * @name GetDiagnosticsInternalFillLevelByPost
     * @request POST:/diagnostics/internal-fill-levels
     * @response `200` `DiagnosticsFillLevelListResponse` A paged array of fill level diagnostics for the assets.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDiagnosticsInternalFillLevelByPost {
        export type RequestParams = {};
        export type RequestQuery = {
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            criticality?: Criticality[];
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'criticality' | 'asset_name' | 'engine_oil' | 'ad_blue' | 'fuel' | 'washer_fluid';
        };
        export type RequestBody = DiagnosticsListRequestBody;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticsFillLevelListResponse;
    }
    /**
     * No description
     * @name SummaryDiagnostics6
     * @request SUMMARY:/diagnostics/brake-linings
     * @originalName summaryDiagnostics
     * @duplicate
     */
    export namespace SummaryDiagnostics6 {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }
    /**
     * @description Lists just the brake linings for all assets
     * @tags diagnostics
     * @name GetDiagnosticsBrakeLinings
     * @request GET:/diagnostics/brake-linings
     * @response `200` `DiagnosticsBrakeLiningListResponse` A paged array of brake linings diagnostics for the assets.
     * @response `default` `Error` unexpected error
     */
    export namespace GetDiagnosticsBrakeLinings {
        export type RequestParams = {};
        export type RequestQuery = {
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            criticality?: Criticality[];
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'criticality' | 'asset_name' | 'left_brake' | 'right_brake';
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticsBrakeLiningListResponse;
    }
    /**
     * @description Lists just the brake linings for a set of assets. Note that this POST endpoint does not create or modify data, it just serves as an alternative for the GET endpoint with assetIds included in the body rather than the URL. While it's a bit unorthodox, this helps avoid situations where the GET-URL becomes too long for the browser to dispatch the request, particularly in IE.
     * @tags diagnostics
     * @name PostDiagnosticsBrakeLinings
     * @request POST:/diagnostics/brake-linings
     * @response `200` `DiagnosticsBrakeLiningListResponse` A paged array of fill level diagnostics for the assets.
     * @response `default` `Error` unexpected error
     */
    export namespace PostDiagnosticsBrakeLinings {
        export type RequestParams = {};
        export type RequestQuery = {
            /**
             * A query parameter indicating from which position on a paginated result should be returned.
             * @format uuid
             */
            start_from?: string;
            /**
             * The total number of entries that should be returned in a paginated response. Default and maximum value is 40.
             * @min 1
             * @max 40
             */
            limit?: number;
            /** Indicating the sort direction. Use this in combination with `sort_by`. */
            sort_direction?: SortDirection;
            criticality?: Criticality[];
            /**
             * Indicating the sort column. Use this in combination with `sort_direction`.
             *
             */
            sort_by?: 'criticality' | 'asset_name' | 'left_brake' | 'right_brake';
        };
        export type RequestBody = DiagnosticsListRequestBody;
        export type RequestHeaders = {};
        export type ResponseBody = DiagnosticsBrakeLiningListResponse;
    }
}

export declare namespace Assettree {
    /**
     * No description
     * @tags assettree
     * @name GetAssetTree
     * @summary Get the data for the asset tree
     * @request GET:/assettree
     * @response `200` `AssetTreeResponse` Asset Tree response
     */
    export namespace GetAssetTree {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = AssetTreeResponse;
    }
}

export declare namespace Products {
    /**
     * No description
     * @tags products
     * @name GetBookingOverview
     * @summary Overview about the number of bookings
     * @request GET:/products/booking-overview
     * @response `200` `BookingOverview` Returns the number of assets for overall, for each product and for unbooked assets.
     * @response `default` `Error` unexpected error
     */
    export namespace GetBookingOverview {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = BookingOverview;
    }
    /**
     * No description
     * @tags products
     * @name GetPricing
     * @summary Pricing according to product levels
     * @request GET:/products/pricing
     * @response `200` `Pricing` Returns the prices of ServiceCare S, M.
     * @response `default` `Error` unexpected error
     */
    export namespace GetPricing {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = Pricing;
    }
}

export declare namespace Validation {
    /**
     * No description
     * @tags validation
     * @name GetPhoneNumbers
     * @summary Get a list of validated and formatted phone numbers
     * @request GET:/validation/phone-numbers
     * @response `200` `PhoneNumbersResponse` A list of PhoneNumbers with valid flag
     */
    export namespace GetPhoneNumbers {
        export type RequestParams = {};
        export type RequestQuery = {
            /** A list of phone numbers */
            numbers?: string[];
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = PhoneNumbersResponse;
    }
}

export declare namespace Feedback {
    /**
     * No description
     * @name SummaryFeedback
     * @request SUMMARY:/feedback
     */
    export namespace SummaryFeedback {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }
    /**
     * @description post feedback data collected from the user via feedback form into the endpoint, data consists of service rating, free text, and future contact confirmation.
     * @tags feedback
     * @name PostFeedbackInfo
     * @request POST:/feedback
     * @response `201` `void` feedback information sucessfully processed
     * @response `default` `Error` unexpected error
     */
    export namespace PostFeedbackInfo {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = Feedback;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }
}

export declare namespace QuickFeedback {
    /**
     * No description
     * @tags feedback
     * @name SubmitQuickFeeback
     * @summary User submits a quick feedback using the quick feeback dialog
     * @request POST:/quick-feedback
     * @response `201` `void` feedback information sucessfully processed
     * @response `default` `Error` unexpected error
     */
    export namespace SubmitQuickFeeback {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = QuickFeedback;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }
}

export declare namespace WorkshopAssignments {
    /**
     * No description
     * @tags workshop-assignments
     * @name PostWorkshopAssignments
     * @summary Create a workshop assignment for multiple assets.
     * @request POST:/workshop-assignments
     * @response `204` `void` Assets were assigned successfully to the workshop.
     * @response `default` `Error` unexpected error
     */
    export namespace PostWorkshopAssignments {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = WorkshopAssignments;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }
}

export declare namespace WorkshopCountries {
    /**
     * No description
     * @tags workshops
     * @name GetWorkshopCountries
     * @summary Get the countries with available workshops
     * @request GET:/workshop-countries
     * @response `200` `WorkshopCountriesResponse` A list of WorkshopCountry objects with available workshops
     */
    export namespace GetWorkshopCountries {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /** Asset types which a workshop should be able to provide service to. */
            asset_types?: AssetType[];
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = WorkshopCountriesResponse;
    }
}

export declare namespace Workshops {
    /**
     * No description
     * @tags workshops
     * @name GetWorkshops
     * @summary Get a list of available workshops
     * @request GET:/workshops
     * @response `200` `WorkshopsResponse` A list of available workshops
     */
    export namespace GetWorkshops {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Search string which filters the results. */
            q?: string;
            /** Asset types which a workshop should be able to provide service to. */
            asset_types?: AssetType[];
            /**
             * Alpha-2 country code
             * @example AT
             */
            country_code?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = WorkshopsResponse;
    }
}

export declare namespace Users {
    /**
     * No description
     * @tags users
     * @name GetUsers
     * @summary Get a list of managed users
     * @request GET:/users
     * @response `200` `ManagedUsersList` A list of managed users
     */
    export namespace GetUsers {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
            /** Search string which filters the results. */
            q?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ManagedUsersList;
    }
    /**
     * No description
     * @tags users
     * @name GetUserById
     * @summary Get one managed user
     * @request GET:/users/{id}
     * @response `200` `ManagedUser` The requested managed user
     */
    export namespace GetUserById {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ManagedUser;
    }
}

export declare namespace ProActiveMaintenanceSettings {
    /**
     * No description
     * @tags pro-active-maintenance-settings
     * @name UpdateProActiveMaintenanceSettingsMultiple
     * @summary Change the pro-active maintenance settings of the multiple assets
     * @request POST:/pro-active-maintenance-settings
     * @response `204` `void` Pro-active maintenance settings has been created/updated
     * @response `default` `Error` unexpected error
     */
    export namespace UpdateProActiveMaintenanceSettingsMultiple {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ProActiveMaintenanceSettingsMultiple;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }
    /**
     * No description
     * @tags pro-active-maintenance-settings
     * @name GetProActiveMaintenanceSettingsById
     * @summary Get the Pro-active maintenance settings for a specific asset
     * @request GET:/pro-active-maintenance-settings/{asset_id}
     * @response `200` `ProActiveMaintenanceSettings` The pro-active maintenance settings requested for the asset
     */
    export namespace GetProActiveMaintenanceSettingsById {
        export type RequestParams = {
            /** @format uuid */
            assetId: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ProActiveMaintenanceSettings;
    }
    /**
     * No description
     * @tags pro-active-maintenance-settings
     * @name UpdateProActiveMaintenanceSettings
     * @summary Change the pro-active maintenance settings of the asset
     * @request PUT:/pro-active-maintenance-settings/{asset_id}
     * @response `200` `ProActiveMaintenanceSettings` Pro-active maintenance settings has been created/updated
     * @response `default` `Error` unexpected error
     */
    export namespace UpdateProActiveMaintenanceSettings {
        export type RequestParams = {
            /** @format uuid */
            assetId: string;
        };
        export type RequestQuery = {};
        export type RequestBody = ProActiveMaintenanceSettings;
        export type RequestHeaders = {};
        export type ResponseBody = ProActiveMaintenanceSettings;
    }
}

export declare namespace UptimeContactPersonAssignment {
    /**
     * @description Adding one or two uptime contact person(s) to service care product L. Other product types have no uptime contact persons.
     * @tags uptime
     * @name PostUptimeContactPerson
     * @request POST:/uptime-contact-person-assignment
     * @response `204` `void` Successful assignment of uptime contact person(s)
     * @response `default` `Error` unexpected error
     */
    export namespace PostUptimeContactPerson {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UptimeContactPersonAssignment;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }
}

export declare namespace Overview {
    /**
     * @description Returns the information needed to create the uptime widgets to display the current dtc's
     * @tags overview
     * @name GetUptimeWidgetsList
     * @summary The uptime widgets list
     * @request GET:/overview/widgets/uptime
     * @response `200` `UptimeWidgetsList` The list of widgets for the uptime
     * @response `default` `Error` Unexpected error
     */
    export namespace GetUptimeWidgetsList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = UptimeWidgetsList;
    }
    /**
     * @description Fetches a single uptime ticket by it's id.
     * @tags overview
     * @name GetUptimeTicket
     * @summary Fetch uptime ticket
     * @request GET:/overview/widgets/uptime/{ticket_id}
     * @response `200` `UptimeWidget` The uptime widget could be fetched
     * @response `default` `Error` Unexpected error
     */
    export namespace GetUptimeTicket {
        export type RequestParams = {
            /** @format uuid */
            ticketId: string;
        };
        export type RequestQuery = {
            /** The locale of the user used for sorting, filtering and translating. */
            locale: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = UptimeWidget;
    }
    /**
     * @description Deletes a ticket associated to a widget provided you pass the ticket_id
     * @tags overview
     * @name DeleteUptimeWidget
     * @summary Deletes a specific uptime ticket
     * @request DELETE:/overview/widgets/uptime/{ticket_id}
     * @response `204` `void` The widget was successfully deleted
     * @response `default` `Error` Unexpected error
     */
    export namespace DeleteUptimeWidget {
        export type RequestParams = {
            /** @format uuid */
            ticketId: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = void;
    }
    /**
     * @description Returns the information needed to create the maintenance widgets
     * @tags overview
     * @name GetMaintenanceWidgetsList
     * @summary The maintenance widget list
     * @request GET:/overview/widgets/maintenance
     * @response `200` `MaintenanceWidgetsList` The list of maintenance widgets
     * @response `default` `Error` Unexpected error
     */
    export namespace GetMaintenanceWidgetsList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = MaintenanceWidgetsList;
    }
    /**
     * @description Returns the information needed to create list of vehicle status
     * @tags overview
     * @name GetVehicleStatusList
     * @summary The vehicle status widget
     * @request GET:/overview/widgets/vehicle-status
     * @response `200` `ListWidget` The list of vehicle status
     * @response `default` `Error` Unexpected error
     */
    export namespace GetVehicleStatusList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ListWidget;
    }
}

export declare namespace HomeWorkshop {
    /**
     * @description Returns the Home Workshop assigned to vehicles of the fleet of the user
     * @tags home-workshop
     * @name GetHomeWorkshop
     * @summary Get the current home workshop
     * @request GET:/home-workshop
     * @response `200` `HomeWorkshop` Home Workshop
     */
    export namespace GetHomeWorkshop {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = HomeWorkshop;
    }
    /**
     * @description Sets the Home Workshop and reassigns all vehicles part of the fleet of the user, that are currently assigned to the default workshop.
     * @tags home-workshop
     * @name PostHomeWorkshop
     * @summary Sets the Home Workshop
     * @request POST:/home-workshop
     * @response `200` `HomeWorkshop` Vehicles assigned to Home Workshop
     */
    export namespace PostHomeWorkshop {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = HomeWorkshopAssignment;
        export type RequestHeaders = {};
        export type ResponseBody = HomeWorkshop;
    }
}
