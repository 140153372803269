import axios, { CancelTokenSource } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Asset, ProActiveMaintenanceSettings } from '../../../../generated/fleetAggregationServiceTypes';

type InitialProactiveMaintenanceSettingsState = {
    initialProactiveMaintenanceSettings: ProActiveMaintenanceSettings;
    isLoading: boolean;
    error: Error | null;
};

function getEmptyProactiveMaintenanceSettings(): ProActiveMaintenanceSettings {
    return { maintenance_components: [], custom_components: true };
}

export const useProactiveMaintenanceSettings = (asset: Asset) => {
    const intl = useIntl();

    // represents the state of the settings which are loaded from the backend and manages the loading
    const [initialProactiveMaintenanceSettingsState, setInitialProactiveMaintenanceSettingsState] = useState<InitialProactiveMaintenanceSettingsState>({
        initialProactiveMaintenanceSettings: getEmptyProactiveMaintenanceSettings(),
        isLoading: true,
        error: null,
    });

    const [proactiveMaintenanceSettingsReloadTrigger, setProactiveMaintenanceSettingsReloadTrigger] = useState<{}>({});

    // holds the state of the UI
    const [editableProactiveMaintenanceSettingsState, setEditableProactiveMaintenanceSettingsState] = useState<ProActiveMaintenanceSettings>(
        cloneDeep(initialProactiveMaintenanceSettingsState.initialProactiveMaintenanceSettings)
    );

    // sets the editable UI state to the last loaded state (e.g. after loading)
    useEffect(() => {
        setEditableProactiveMaintenanceSettingsState(cloneDeep(initialProactiveMaintenanceSettingsState.initialProactiveMaintenanceSettings));
    }, [initialProactiveMaintenanceSettingsState.initialProactiveMaintenanceSettings]);

    useEffect(() => {
        let axiosCancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        const fetchProactiveMaintenanceSettings = async () => {
            try {
                setInitialProactiveMaintenanceSettingsState({
                    initialProactiveMaintenanceSettings: getEmptyProactiveMaintenanceSettings(),
                    isLoading: true,
                    error: null,
                });

                const params: ProActiveMaintenanceSettings.GetProActiveMaintenanceSettingsById.RequestQuery = { locale: intl.locale };

                const res = await axios.get<ProActiveMaintenanceSettings.GetProActiveMaintenanceSettingsById.ResponseBody>(
                    `${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/pro-active-maintenance-settings/${asset.id}`,
                    { params, cancelToken: axiosCancelTokenSource?.token }
                );
                axiosCancelTokenSource = null;
                setInitialProactiveMaintenanceSettingsState({ initialProactiveMaintenanceSettings: res.data, isLoading: false, error: null });
            } catch (e: any) {
                axiosCancelTokenSource = null;
                if (!axios.isCancel(e)) {
                    setInitialProactiveMaintenanceSettingsState({
                        initialProactiveMaintenanceSettings: getEmptyProactiveMaintenanceSettings(),
                        isLoading: false,
                        error: e,
                    });
                }
            }
        };
        fetchProactiveMaintenanceSettings();

        return () => {
            if (axiosCancelTokenSource) {
                axiosCancelTokenSource.cancel();
            }
        };
    }, [asset, proactiveMaintenanceSettingsReloadTrigger, intl.locale]);
    // change of asset is triggering the reload so that it also reloads when the asset is reloaded (e.g. after saving) and not only if the asset id changed

    const reloadTriggerFunction = () => {
        setProactiveMaintenanceSettingsReloadTrigger({});
    };

    return {
        ...initialProactiveMaintenanceSettingsState,
        editableProactiveMaintenanceSettingsState,
        setEditableProactiveMaintenanceSettingsState,
        reloadTriggerFunction,
    };
};
