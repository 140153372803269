import { FormattedMessage } from 'react-intl';

import { isTG2orTG3 } from '../../../../components/Asset';
import { CustomDateTime } from '../../../../data/formatting/CustomDateTime';
import { CustomMileage } from '../../../../data/formatting/CustomMileage';
import { Asset, AssetType, Criticality, FillLevels, FillLevelType } from '../../../../generated/fleetAggregationServiceTypes';
import { hasFeature } from '../../../../productsConfig';
import { getIconClassesForCriticality } from '../../../../utils/criticalityMappings';
import { ENGINE_OIL_OUTDATED_HOURS, ENGINE_OIL_STATE_LOW, ENGINE_OIL_STATE_LOW_V2, ENGINE_OIL_STATE_OK, isEngineOilOutdated } from '../../../../utils/engineOilUtils';
import { EngineOilPreConditionsAndFeedback } from '../EngineOilPreConditionsAndFeedback';
import { SidebarStatusBar } from '../SidebarStatusBar';
import EngineOilDtcElement from './EngineOilDtcElement';
import { EngineOilDtcTooltip } from './EngineOilDtcTooltip';
import { InformationSignWithOutdatedTooltip } from './InformationSignWithOutdatedTooltip';
import { SidebarDataLimited } from './SidebarDataLimited';

type SidebarEngineOilProps = {
    engineOil: FillLevels;
    asset: Asset;
};

export const SidebarEngineOil = ({ engineOil, asset }: SidebarEngineOilProps) => {
    const overview = engineOil.overview;
    const engineOilLevel = engineOil.items[0];
    const noOverviewData = !overview.warning && !overview.danger && !overview.normal && !overview.unknown;
    const engineOilDate = engineOil.measured_at_date;
    const engineOilMileage = engineOil.measured_at_distance;
    const engineOilType = engineOilLevel?.type;
    const isEngineOilDid = engineOilType === FillLevelType.EngineOilDid;
    const isEngineOilSensorSignalV2 = engineOilType === FillLevelType.EngineOilV2;
    const isReadingNotPossible = (isEngineOilDid || isEngineOilSensorSignalV2) && isEngineOilOutdated(engineOilDate, ENGINE_OIL_OUTDATED_HOURS);
    const hasEngineOilDtc = isEngineOilSensorSignalV2 && engineOilLevel.engine_oil_dtc_spn !== undefined;

    const showEngineOilDidDetails = () => {
        const engineOilDidState = engineOilLevel.level_engine_oil_state;
        const statusMessageKey = `intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.${engineOilDidState}`;
        const recommendationMessageKey = isReadingNotPossible
            ? 'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.recommendation.readingNotPossible'
            : `intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.recommendation.${engineOilDidState}`;
        const hasRecommendation = engineOilDidState !== ENGINE_OIL_STATE_OK || isReadingNotPossible;

        return (
            <tr>
                <td style={{ borderTop: 'none', paddingTop: 0 }}>
                    <div className="text-size-14 text-color-gray-darkest">
                        <FormattedMessage id={statusMessageKey} />
                    </div>
                    {hasRecommendation && (
                        <div className="text-size-14 text-color-dark">
                            <FormattedMessage id={recommendationMessageKey} />
                        </div>
                    )}
                </td>
            </tr>
        );
    };

    const showEngineOilSensorSignalV2Details = () => {
        const engineOilState = engineOilLevel.level_engine_oil_state;
        const engineOilStateRecommendation = engineOilState === ENGINE_OIL_STATE_LOW ? ENGINE_OIL_STATE_LOW_V2 : engineOilState;
        const statusMessageKey = `intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.${engineOilState}`;
        const recommendationMessageKey = isReadingNotPossible
            ? 'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.recommendation.readingNotPossible'
            : `intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.recommendation.${engineOilStateRecommendation}`;
        const hasRecommendation = engineOilState !== ENGINE_OIL_STATE_OK || isReadingNotPossible;

        return (
            <tr>
                <td style={{ borderTop: 'none', paddingTop: 0 }}>
                    <div className="text-size-14 text-color-gray-darkest">
                        <FormattedMessage id={statusMessageKey} />
                    </div>
                    {hasRecommendation && (
                        <div className="text-size-14 text-color-dark">
                            <FormattedMessage id={recommendationMessageKey} />
                        </div>
                    )}
                </td>
            </tr>
        );
    };

    const showExtraInformationBanners = () => {
        if (isTG2orTG3(asset) || asset.type === AssetType.Bus) {
            return (
                <tr>
                    <td>
                        <span className="rioglyph rioglyph-dangerousgoods margin-right-5" />
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.data_not_accurate" />
                    </td>
                </tr>
            );
        } else if (asset.hasOCU3DeviceType && engineOilLevel.percentage === 0) {
            return (
                <tr>
                    <td>
                        <span className="rioglyph rioglyph-dangerousgoods margin-right-5" />
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.tge.engine_oil.not_supported" />
                    </td>
                </tr>
            );
        } else {
            return null;
        }
    };

    const renderEngineOilStatus = () => {
        if (engineOilLevel.percentage !== undefined) {
            return <SidebarStatusBar percentage={engineOilLevel.percentage} criticality={engineOilLevel.criticality} disableColor={isReadingNotPossible} />;
        } else {
            switch (engineOilLevel.criticality) {
                case Criticality.Danger:
                    return (
                        <span className="text-color-danger text-uppercase text-bold">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.refill" />
                        </span>
                    );
                case Criticality.Warning:
                    return (
                        <span className="text-color-warning text-uppercase text-bold">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.refill" />
                        </span>
                    );
                case Criticality.Normal:
                    return (
                        <span className="text-uppercase">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.ok" />
                        </span>
                    );
                case Criticality.Unknown:
                    return (
                        <span className="text-color-gray">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.criticality.unknown" />
                        </span>
                    );
            }
        }
    };

    const renderEngineOilTableRow = () => {
        const renderEngineOilDetails = () => {
            switch (engineOilLevel.type) {
                case FillLevelType.EngineOilDid:
                    return showEngineOilDidDetails();
                case FillLevelType.EngineOilV2:
                    return showEngineOilSensorSignalV2Details();
                default:
                    return showExtraInformationBanners();
            }
        };

        return (
            <>
                <table className="table table-condensed">
                    <tbody>
                        <tr className="border-style-solid border-width-1 border-color-light">
                            <td className="padding-left-0">
                                {isEngineOilSensorSignalV2 && hasEngineOilDtc && (
                                    <div>
                                        <EngineOilDtcElement />
                                        <EngineOilDtcTooltip />
                                    </div>
                                )}
                                {isReadingNotPossible && (
                                    <div className="padding-top-10">
                                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.readingNotPossible" />
                                        <InformationSignWithOutdatedTooltip outdatedHours={ENGINE_OIL_OUTDATED_HOURS} tooltipPosition="right" />
                                    </div>
                                )}
                            </td>
                            <td className="padding-right-0 padding-top-10">
                                {(isEngineOilDid || isEngineOilSensorSignalV2) && <EngineOilPreConditionsAndFeedback engineOilType={engineOilType} />}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-condensed">
                    <tbody>
                        <tr key={engineOilLevel.type} className="border-none">
                            <td className="width-30pct border-none align-top">
                                <table className="width-100pct">
                                    <tbody>
                                        <tr>
                                            <td className="border-none padding-left-2">
                                                <span className={getIconClassesForCriticality(engineOilLevel.criticality, 'placeholder')} />
                                            </td>
                                            <td className="border-none width-90pct padding-left-10">
                                                <span>{<FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.type.engine_oil" />}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td className="width-70pct border-none">
                                <table className="width-100pct">
                                    <tbody>
                                        <tr className="width-100pct">
                                            <td className="border-none width-90pct padding-right-10">{renderEngineOilStatus()}</td>
                                            <td className="border-none width-10pct padding-right-25 text-right">
                                                {engineOilLevel.percentage !== undefined && (
                                                    <span className={isReadingNotPossible ? 'text-color-gray' : ''}>{`${engineOilLevel.percentage}%`}</span>
                                                )}
                                            </td>
                                        </tr>
                                        {renderEngineOilDetails()}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    };

    const renderContent = () => {
        if (asset.hasCM4DeviceType) {
            return (
                <table className="table">
                    <tbody className="">
                        <tr className="border-style-solid border-width-1 border-color-light">
                            <td className="padding-left-0">
                                <span className="rioglyph rioglyph-dangerousgoods margin-right-5" />
                                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.data_not_possible" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        } else if (noOverviewData) {
            return (
                <div className="display-flex justify-content-between margin-bottom-15">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.no_data" />
                    {isEngineOilDid && <EngineOilPreConditionsAndFeedback engineOilType={engineOilType} />}
                </div>
            );
        } else if (hasFeature('diagnosticDetailsView', asset)) {
            return <>{renderEngineOilTableRow()}</>;
        } else
            return (
                <>
                    {isEngineOilSensorSignalV2 && hasEngineOilDtc && (
                        <div className="padding-bottom-5">
                            <EngineOilDtcElement />
                            <EngineOilDtcTooltip />
                        </div>
                    )}
                    {isReadingNotPossible && (
                        <div className="padding-bottom-5">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.readingNotPossible" />
                            <InformationSignWithOutdatedTooltip outdatedHours={ENGINE_OIL_OUTDATED_HOURS} tooltipPosition="right" />
                        </div>
                    )}
                    <SidebarDataLimited items={engineOil.items} overview={overview} asset={asset} overrideSideColorToGrey={isReadingNotPossible} />
                </>
            );
    };

    return (
        <>
            <div className="display-flex" style={{ alignItems: 'center' }}>
                <h6 className="text-uppercase text-color-dark">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_fill_level" />
                </h6>
                &nbsp;
                {!asset.hasCM4DeviceType && (
                    <span className="text-color-gray">
                        {isReadingNotPossible && <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.latestValidReading" />}
                        &nbsp;
                        <CustomDateTime value={engineOilDate} showWeekDay={false} /> {engineOilMileage ? '|' : ''} <CustomMileage mileage={engineOilMileage} unit={'kilometer'} />
                    </span>
                )}
            </div>
            {renderContent()}
        </>
    );
};
