import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configuration/setup/store';

interface BatteryTableState {
    filteredAssets: string[];
}

const initialState: BatteryTableState = {
    filteredAssets: [],
};

const assetTreeSlice = createSlice({
    name: 'assetTree',
    initialState,
    reducers: {
        assetsChanged: (state, action: PayloadAction<string[]>) => {
            state.filteredAssets = action.payload;
        },
    },
});

// Actions
export const { assetsChanged } = assetTreeSlice.actions;

// Selectors
export const getFilteredAssets = (state: RootState) => state.assetTree.filteredAssets;

export default assetTreeSlice.reducer;
