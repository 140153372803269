import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';
import { ProActiveMaintenanceStatus as ProActiveMaintenanceStatusEnum } from '../../../generated/fleetAggregationServiceTypes';

type ProActiveMaintenanceStatusProps = {
    proActiveMaintenanceStatus: ProActiveMaintenanceStatusEnum;
    withText: boolean;
};

export const proActiveStatusColorMapping: Record<ProActiveMaintenanceStatusEnum, string> = {
    [ProActiveMaintenanceStatusEnum.On]: 'text-color-info',
    [ProActiveMaintenanceStatusEnum.Off]: 'text-color-gray',
    [ProActiveMaintenanceStatusEnum.PartiallyOn]: 'text-color-rating-3',
};

export const ProActiveMaintenanceStatus = ({ proActiveMaintenanceStatus, withText }: ProActiveMaintenanceStatusProps) => {
    if (withText)
        return (
            <span>
                <span className={`rioglyph rioglyph-sphere ${proActiveStatusColorMapping[proActiveMaintenanceStatus]} margin-right-5`} />
                <FormattedMessage id={`intl-msg:fleetstatus.diagnostics.proactivemaintenance.status.${proActiveMaintenanceStatus}`} />
            </span>
        );

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip width="auto">
                    <FormattedMessage id={`intl-msg:fleetstatus.diagnostics.proactivemaintenance.status.${proActiveMaintenanceStatus}`} />
                </Tooltip>
            }
        >
            <span className={`rioglyph rioglyph-sphere ${proActiveStatusColorMapping[proActiveMaintenanceStatus]} margin-left-5`} />
        </OverlayTrigger>
    );
};
