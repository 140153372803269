declare global {
    interface Window {
        dataLayer: Array<any>;
    }
}

// for reference see: https://collaboration.msi.audi.com/confluence/pages/viewpage.action?pageId=677217331#GoogleAnalytics4:GettingStarted-CreateEventTrackingtotrackuserinteractions
type GoogleAnalytics4Parameters = {
    element_name: string;
    component_name:
        | 'ApplicationLayout'
        | 'ApplicationHeader'
        | 'BottomSheet'
        | 'Sidebar'
        | 'MainNavigation'
        | 'SubNavigation'
        | 'AppNavigationBar'
        | 'Pager'
        | 'Button'
        | 'Checkbox'
        | 'Clearablelnput'
        | 'LoadMoreButton'
        | 'NumberControl'
        | 'RadioButton '
        | 'Slider'
        | 'Switch'
        | 'ToggleButton'
        | 'Collapse'
        | 'Expander'
        | 'Fade'
        | 'AssetTree'
        | 'AutoSuggest'
        | 'ButtonDropdown'
        | 'Select'
        | 'DatePicker'
        | 'FilePicker'
        | 'TimePicker'
        | 'StatusBar'
        | 'SteppedProgress '
        | 'AnimatedNumber'
        | 'Carousel'
        | 'ContentLoader'
        | 'DataTabs'
        | 'Dialog'
        | 'NoData'
        | 'ErrorState'
        | 'NotFoundState'
        | 'EmptyState'
        | 'ForbiddenState'
        | 'MaintenanceState'
        | 'CustomState'
        | 'NotBookedState'
        | 'StatsWidget'
        | 'TableToolbar'
        | 'TableSearch'
        | 'TableViewToggles'
        | 'TableCardsSorting'
        | 'TableSettingDialog'
        | 'Teaser'
        | 'Timeline'
        | 'AreaChart'
        | 'LineChart'
        | 'BarChart'
        | 'PieChart'
        | 'RadialBarChart'
        | 'Map'
        | 'SingleMapMarker'
        | 'ClusterMapMarker'
        | 'Marker';
    trigger: 'click' | 'visibility';
    flow_name?: string | undefined;
    identification_type?: string | undefined;
    connection_type?: 'inbound' | 'outbound' | undefined;
    connection_brand?: 'scania' | 'idem' | 'fleetboard' | 'volvo' | undefined;
    components?: number | undefined;
    method?: string | undefined;
    search_term?: string | undefined;
    currency?: 'Eur' | 'Usd' | undefined;
    value?: number | undefined;
    page_location?: string | undefined;
    page_title?: string | undefined;
    ecommerce?: object | undefined;
    asset_id?: string | undefined;
    rating?: number | undefined;
    callback_requested?: 'yes' | 'no' | undefined;
};

type GoogleAnalytics4UserProperties = {
    login_method?: 'mobile' | 'email' | undefined;
    account_id?: string | undefined;
    tenant?: string | undefined; // rio-eu.test, rio-eu.prod, ...
    user_id?: string | undefined;
};

type GoogleAnalyticsCustomEvents =
    | 'open_sidebar_myfleet'
    | 'sort_table_myfleet'
    | 'search_table_myfleet'
    | 'close_sidebar_myfleet'
    | 'filter_table_diagnostics'
    | 'learn_about_proactive_maintenance'
    | 'open_sidebar_diagnostics_appointments'
    | 'open_sidebar_diagnostics'
    | 'close_sidebar_diagnostics'
    | 'open_sidebar_diagnostics_proactive'
    | 'sort_table_diagnostics'
    | 'show_all_components'
    | 'select_asset_tree_vehicles'
    | 'search_table_damages'
    | 'search_table_diagnostics'
    | 'filter_table_diagnostics_criticality'
    | 'open_sidebar_damages'
    | 'close_sidebar_damages'
    | 'create_custom_components'
    | 'assign_workshop'
    | 'assign_contact_person'
    | 'assign_workshop_and_contact_person'
    | 'change_maintenance_proactive_components'
    | 'change_custom_proactive_components'
    | 'export_diagnostics_data'
    | 'click_widget_maintenance'
    | 'click_widget_damage'
    | 'click_widget_vehicle_status'
    | 'open_sidebar_uptime'
    | 'close_sidebar_uptime'
    | 'delete_widget_uptime'
    | 'collapse_dtc'
    | 'open_notification_settings';

const AnalyticsUtils = {
    push: (event: GoogleAnalyticsCustomEvents, params: GoogleAnalytics4Parameters, user?: GoogleAnalytics4UserProperties) => {
        // skip in development but not on jest tests
        if (!globalThis.process?.env?.JEST_WORKER_ID && import.meta.env.DEV) {
            console.info('[DEVELOPMENT] skip GA4 Event: ', event, user);
            return;
        }
        const dataLayer = window.dataLayer;
        if (dataLayer) dataLayer.push({ event, ...params, ...user });
    },
    pushInitialUserProperties: () => {
        // skip in development but not on jest tests
        if (!globalThis.process?.env?.JEST_WORKER_ID && import.meta.env.DEV) {
            console.info('[DEVELOPMENT] skip push of GA4 static user properties');
            return;
        }
        const dataLayer = window.dataLayer;
        if (dataLayer) {
            // 'role' is a user property will be statically defined.
            // for more details of user properties see: https://confluence.collaboration-man.com/display/RTTX/Google+Analytics%3A+Getting+Started
            // Note: We don't need to set the 'tenant' because is taken from the rio-token and pushed to the datalayer in the user-menu
            dataLayer.push({ role: 'mansc-customer.user' });
        }
    },
};

export default AnalyticsUtils;
