import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import { FormattedMessage } from 'react-intl';
import { CustomMileage } from '../../data/formatting/CustomMileage';
import { Criticality } from '../../generated/fleetAggregationServiceTypes';
import { checkDataInconsistency } from './DueDate';

type DueInProps = {
    mileage: number | undefined;
    criticality: Criticality;
    isMaintenanceComponent: boolean;
    inSpan?: boolean;
    isSourceSidebar?: boolean;
    isAssetWithOCU3Device: boolean;
};

const isMileageInconsistent = (mileage: number, criticality: Criticality) => {
    switch (criticality) {
        case Criticality.Danger:
            return mileage > 0;

        case Criticality.Warning:
            return true;

        case Criticality.Normal:
            return mileage < 0;

        default:
            return false;
    }
};

const DueIn = ({ mileage, criticality, isMaintenanceComponent, inSpan, isSourceSidebar = false, isAssetWithOCU3Device }: DueInProps) => {
    const DASH = '- ';

    const getPlacement = (isSidebar: boolean): string => (isSidebar ? 'top' : 'right');

    const InconsistentDueInTooltip = (isStatusAlmostDue: boolean) => (
        <Popover id="dueIn-popover" placement={getPlacement(isSourceSidebar)} className="margin-left-5 margin-bottom-5 max-width-250">
            {isStatusAlmostDue ? (
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.dueIn.AlmostDue.tooltip" />
            ) : (
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.dueIn.tooltip" />
            )}
        </Popover>
    );

    if (mileage === undefined) return null;

    if (checkDataInconsistency(isMaintenanceComponent, isAssetWithOCU3Device) && isMileageInconsistent(mileage, criticality)) {
        return (
            <span>
                <span>{DASH}</span>
                <OverlayTrigger placement={getPlacement(isSourceSidebar)} overlay={InconsistentDueInTooltip(criticality == Criticality.Warning)}>
                    <span className="rioglyph rioglyph-exclamation-sign text-color-dark text-size-16 width-auto margin-top-0" />
                </OverlayTrigger>
            </span>
        );
    }

    return <CustomMileage mileage={mileage} unit="kilometer" inSpan={inSpan} />;
};

export default DueIn;
