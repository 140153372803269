import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';

export const UptimeContactPersonInfoTooltip = () => (
    <OverlayTrigger
        overlay={
            <Tooltip>
                <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.tooltip.supported_languages" />
                <br />
                <span className="text-bold">
                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.tooltip.change_user_language" />
                </span>
            </Tooltip>
        }
        placement="right"
    >
        <span className={'rioglyph rioglyph-info-sign text-color-dark'} />
    </OverlayTrigger>
);
