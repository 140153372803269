import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage } from 'react-intl';
import { EngineOilDidPreconditionsElement } from './EngineOilDidPreconditionsElement';
import { EngineOilSensorSignalV2PreconditionsElement } from './EngineOilSensorSignalV2PreconditionsElement';
import { FillLevelType } from '../../../../generated/fleetAggregationServiceTypes';

type EngineOilPreconditionsDialogProps = {
    openDialog: boolean;
    closeDialog: () => void;
    engineOilType: string;
};

const EngineOilPreconditionsDialog = ({ openDialog, closeDialog, engineOilType }: EngineOilPreconditionsDialogProps) => {
    return (
        <Dialog
            body={
                openDialog ? (
                    <div className="prediction-dialog-chart padding-x-20 padding-bottom-10">
                        {engineOilType === FillLevelType.EngineOilV2 && <EngineOilSensorSignalV2PreconditionsElement />}
                        {engineOilType === FillLevelType.EngineOilDid && <EngineOilDidPreconditionsElement />}
                    </div>
                ) : null
            }
            title={<FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.title" />}
            show={openDialog}
            showCloseButton
            onHide={closeDialog}
            footer={null}
            bsSize={engineOilType === FillLevelType.EngineOilDid ? Dialog.SIZE_SM : null}
        />
    );
};

export default EngineOilPreconditionsDialog;
