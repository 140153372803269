import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { customMileageFormatter } from '../../../../data/formatting/CustomMileage';
import { MaintenanceItemUpdateProps } from '../../../../generated/fleetAggregationServiceTypes';
import './NotSyncedInfoTooltip.css';

const DIFF_24_HOURS = 24 * 60 * 60 * 1000;

export const NotSyncedInfoTooltip = ({ measured_at_date, measured_at_distance }: MaintenanceItemUpdateProps) => {
    const intl = useIntl();

    const notSyncedLimitDays = 1;

    const dayDifference = (new Date().getTime() - new Date(measured_at_date || 0).getTime()) / DIFF_24_HOURS;
    if (dayDifference < notSyncedLimitDays) {
        return null;
    }

    let toolTipSpecification = '';

    if (measured_at_date !== undefined && measured_at_distance !== undefined) {
        const roundedDayDiff = Math.round(dayDifference);
        const formattedMileage = customMileageFormatter({ unit: 'kilometer', mileage: measured_at_distance, intl }) || '';
        const messageSpecification = {
            days: roundedDayDiff,
            mileage: formattedMileage,
        };
        toolTipSpecification =
            roundedDayDiff === 1
                ? intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.not_synced.text_specification_singular' }, messageSpecification)
                : intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.not_synced.text_specification_plural' }, messageSpecification);
        toolTipSpecification = ' ' + toolTipSpecification;
    }

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip className="width-40ch">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.not_synced.text" />
                    {toolTipSpecification}
                </Tooltip>
            }
        >
            <span className="label label-default label-condensed margin-left-5">
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.not_synced" />
            </span>
        </OverlayTrigger>
    );
};
