import { FeatureToggle, useFeatureToggle } from '../../hooks/useFeatureToggle';
import { StencilTestMfe } from '../microfrontends/StencilTestMfe';

const StencilTest = () => {
    const { featureToggleValue: isStencilTestFeatureEnabled } = useFeatureToggle(FeatureToggle.ENABLE_STENCIL_TEST);
    if (!isStencilTestFeatureEnabled) {
        return null;
    }
    return <StencilTestMfe />;
};

export default StencilTest;
