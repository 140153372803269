import { FormattedMessage } from 'react-intl';

export const EngineOilSensorSignalV2PreconditionsElement = () => {
    return (
        <div>
            <div className="text-bold">
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.subtitle" />
            </div>
            <ol className="margin-top-25">
                <li className="margin-x-10 padding-y-10">
                    <div className="display-flex justify-content-between align-items-center">
                        <span>
                            <FormattedMessage
                                id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.standingTime"
                                values={{
                                    b: (innerText) => <b>{innerText}</b>,
                                }}
                            />
                        </span>
                        <div className="label label-primary label-condensed">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.serviceCareCheck" />
                        </div>
                    </div>
                </li>
                <li className="margin-x-10 padding-y-10">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.signal" />
                </li>
            </ol>
            <ol className="bg-lightest" start={3}>
                <li className="margin-x-10 padding-y-10">
                    <div className="display-flex justify-content-between align-items-center">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.flatSurface" />
                        <div className="label label-primary label-condensed">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.vehicleOperatorCheck" />
                        </div>
                    </div>
                </li>
                <li className="margin-x-10 padding-y-10">
                    <FormattedMessage
                        id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.ignition"
                        values={{
                            b: (innerText) => <b>{innerText}</b>,
                        }}
                    />
                </li>
                <li className="margin-x-10 padding-y-10">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.engine" />
                </li>
            </ol>
        </div>
    );
};
