import moment from 'moment';
import { FeatureToggle, isFeatureToggleEnabled } from '../hooks/useFeatureToggle';

export const ENGINE_OIL_OUTDATED_HOURS = 24;
export const ENGINE_OIL_STATE_OK = 'ok';
export const ENGINE_OIL_STATE_LOW = 'low';
export const ENGINE_OIL_STATE_LOW_V2 = 'low_v2';
export const ENGINE_OIL_SPN_HOURS = 24;
const ENGINE_OIL_SIGNAL_DID = 'did';
export const ENGINE_OIL_SENSOR_SIGNAL_V2 = 'sensor_signal_v2';
export const ENGINE_OIL_SENSOR_SIGNAL = 'sensor_signal';

export const isEngineOilOutdated = (timestamp: string | undefined, hoursToBeOutdated: number = ENGINE_OIL_OUTDATED_HOURS) => moment().diff(timestamp, 'hours') > hoursToBeOutdated;

export const isEngineOilDid = (signal: string | undefined) => signal === ENGINE_OIL_SIGNAL_DID;
export const isEngineOilSensorSignalV2 = (signal: string | undefined) => signal === ENGINE_OIL_SENSOR_SIGNAL_V2;

export const ftSensorSignalV2QueryParam = isFeatureToggleEnabled(FeatureToggle.ENABLE_ENGINE_OIL_SENSOR_SIGNAL_V2) && {
    ft_sensor_signal_v2: true,
};
