import { FormattedMessage } from 'react-intl';

import { Criticality, FillLevelMeasurements, FillLevelType } from '../../generated/fleetAggregationServiceTypes';

export const renderCriticalWasherFluid = (isTableView: boolean = false) => (isTableView ? <div className="margin-left-15">{renderMessageInBlack()}</div> : renderMessageInBlack());

const renderMessageInBlack = () => (
    <span className="text-color-black text-uppercase text-bold">
        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.refill" />
    </span>
);

export const isCriticalWasherFluid = (fillLevel: FillLevelMeasurements) =>
    fillLevel.type === FillLevelType.WasherFluid && [Criticality.Warning, Criticality.Danger].includes(fillLevel.criticality);
