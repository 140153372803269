import { FormattedMessage } from 'react-intl';

export const EngineOilDidPreconditionsElement = () => {
    return (
        <div>
            <b>
                <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.subtitle'} />
            </b>
            <ol className="margin-top-25">
                <li className="padding-10">
                    <FormattedMessage
                        id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.standingTime'}
                        values={{
                            b: (innerText) => <b>{innerText}</b>,
                        }}
                    />
                </li>
                <li className="padding-10">
                    <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.engine'} />
                </li>
                <li className="padding-10">
                    <FormattedMessage
                        id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.ignition'}
                        values={{
                            b: (innerText) => <b>{innerText}</b>,
                        }}
                    />
                </li>
                <li className="padding-10">
                    <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.flatSurface'} />
                </li>
                <li className="padding-10">
                    <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.externalConnectors'} />
                </li>
                <li className="padding-10">
                    <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.signal'} />
                </li>
            </ol>
            <div className="padding-top-10">
                <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.preconditions.dialog.moreDetails'} />
            </div>
        </div>
    );
};
