import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';

const SolvedByCustomer = () => (
    <OverlayTrigger
        overlay={
            <Tooltip className="width-40ch">
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.components.solved_by_customer" />
            </Tooltip>
        }
        placement="auto"
    >
        <span className={'rioglyph rioglyph-series label-condensed text-color-info'} />
    </OverlayTrigger>
);

export default SolvedByCustomer;
