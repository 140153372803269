import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import { FormattedMessage } from 'react-intl';

export const FillLevelsInfoTooltip = () => (
    <OverlayTrigger
        placement="right"
        overlay={
            <Popover
                id={'axleinfo-popover'}
                className={'margin-left-5'}
                title={
                    <span className="text-bold">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.info_tooltip.title" />
                    </span>
                }
            >
                <div className="max-width-250 bg-white text-color-black">
                    <div>
                        <p>
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.description" />
                        </p>
                        <p>
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.warning" />
                        </p>

                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.critical" />
                    </div>
                </div>
            </Popover>
        }
    >
        <span className="rioglyph rioglyph-info-sign margin-left-5" />
    </OverlayTrigger>
);
