import ListMenu from '@rio-cloud/rio-uikit/lib/es/ListMenu';
import SplitDialog from '@rio-cloud/rio-uikit/lib/es/SplitDialog';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import InfoDialogDisplaySection, { InfoDialogContentType } from './InfoDialogDisplaySection';
import { SetLastSeenToNow } from '../useStoredReleaseNotes';
import { Product } from '../../../../generated/fleetAggregationServiceTypes';
import { ProductLabel } from '../../../../components/Product';

const infoDialogTabSearchParameter = 'infoTab';

type InfoProps = {
    setLastSeenToNow: SetLastSeenToNow;
};

const Info = ({ setLastSeenToNow }: InfoProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const infoTab = new URLSearchParams(location.search).get(infoDialogTabSearchParameter) as InfoDialogContentType | null;
    const feedbackOrigin = new URLSearchParams(location.search).get('feedbackOrigin') as string | undefined;
    const infoTypes = Object.values(InfoDialogContentType);
    const serviceItems = infoTypes.filter(
        (serviceItem) => serviceItem === InfoDialogContentType.welcome || serviceItem === InfoDialogContentType.whatsNew || serviceItem === InfoDialogContentType.feedback
    );
    const functionItems = infoTypes.filter(
        (serviceItem) =>
            serviceItem !== InfoDialogContentType.welcome &&
            serviceItem !== InfoDialogContentType.whatsNew &&
            serviceItem !== InfoDialogContentType.feedback &&
            serviceItem !== InfoDialogContentType.aboutElectricVehicles &&
            serviceItem !== InfoDialogContentType.batteryHealth
    );
    const eMobilityItems = infoTypes.filter((serviceItem) => serviceItem === InfoDialogContentType.aboutElectricVehicles || serviceItem === InfoDialogContentType.batteryHealth);

    return (
        <SplitDialog
            show={!!infoTab}
            title={<FormattedMessage id="intl-msg:fleetstatus.global.module_name" />}
            bsSize="lg"
            leftContent={
                <div>
                    <ListMenu menuItems={getOurServiceItems()} />
                    <ListMenu menuItems={getFunctionItems()} />
                    <ListMenu menuItems={getEMobilityItems()} />
                </div>
            }
            rightContent={!!infoTab && <InfoDialogDisplaySection section={infoTab} setLastSeenToNow={setLastSeenToNow} feedbackOrigin={feedbackOrigin} />}
            onClose={onClose}
        />
    );

    function getOurServiceItems() {
        return [
            {
                group: <FormattedMessage id={'intl-msg:fleetstatus.info.our_service'} />,
                navItems: serviceItems.map((servicePage) => {
                    return {
                        key: servicePage,
                        item: (
                            <span className={`${infoTab === servicePage ? 'active' : ''}`} onClick={handleClick.bind(null, servicePage)}>
                                <FormattedMessage id={`intl-msg:fleetstatus.info.${servicePage}`} />
                            </span>
                        ),
                    };
                }),
            },
        ];
    }

    function getFunctionItems() {
        return [
            {
                group: <FormattedMessage id={'intl-msg:fleetstatus.info.functions'} />,
                navItems: functionItems.map((functionPage) => {
                    return {
                        key: functionPage,
                        item: (
                            <span className={`${infoTab === functionPage ? 'active' : ''}`} onClick={handleClick.bind(null, functionPage)}>
                                <FormattedMessage
                                    id={
                                        functionPage === InfoDialogContentType.proactiveMaintenance
                                            ? 'intl-msg:fleetstatus.asset_preferences.menu_items.proactivemaintenance'
                                            : `intl-msg:fleetstatus.info.${functionPage}`
                                    }
                                />
                                {(functionPage === InfoDialogContentType.proactiveMaintenance || functionPage === InfoDialogContentType.exportDiagnosticData) && (
                                    <span className={'margin-left-5'}>
                                        <ProductLabel product={Product.ServiceCareM} />
                                    </span>
                                )}
                            </span>
                        ),
                    };
                }),
            },
        ];
    }

    function getEMobilityItems() {
        return [
            {
                group: <FormattedMessage id={'e-Mobility'} />,
                navItems: eMobilityItems.map((eMobilityPage) => {
                    return {
                        key: eMobilityPage,
                        item: (
                            <span className={`${infoTab === eMobilityPage ? 'active' : ''}`} onClick={handleClick.bind(null, eMobilityPage)}>
                                <FormattedMessage id={`intl-msg:fleetstatus.info.eMobility.${eMobilityPage}`} />
                            </span>
                        ),
                    };
                }),
            },
        ];
    }

    function onClose() {
        const currentSearchParams = new URLSearchParams(location.search);
        currentSearchParams.delete(infoDialogTabSearchParameter);
        navigate({
            search: currentSearchParams.toString(),
        });
    }

    function handleClick(tab: InfoDialogContentType) {
        openInfoPage(navigate, location.search, tab);
    }
};

export const getSearchParamsString = (locationSearch: string, tab: InfoDialogContentType, feedbackOrigin?: string): string => {
    const searchParams = new URLSearchParams(locationSearch);
    searchParams.delete(infoDialogTabSearchParameter);
    searchParams.append(infoDialogTabSearchParameter, tab);
    if (feedbackOrigin && feedbackOrigin.length > 0) {
        searchParams.set('feedbackOrigin', feedbackOrigin);
    }
    return searchParams.toString();
};

export const openInfoPage = (navigate: NavigateFunction, locationSearch: string, tab: InfoDialogContentType | string, feedbackOrigin?: string) => {
    const currentSearchParams = new URLSearchParams(locationSearch);
    currentSearchParams.delete(infoDialogTabSearchParameter);
    currentSearchParams.append(infoDialogTabSearchParameter, tab);
    if (feedbackOrigin && feedbackOrigin.length > 0) {
        currentSearchParams.set('feedbackOrigin', feedbackOrigin);
    }

    navigate({
        search: currentSearchParams.toString(),
    });
};

export default Info;
