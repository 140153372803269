import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import { FormattedMessage } from 'react-intl';
import './BookingInfoTooltip.css';

export const BookingInfoTooltip = () => (
    <OverlayTrigger
        placement="right"
        overlay={
            <Popover
                className={'z-index-max margin-left-5'}
                id="popover"
                bsStyle={'z-index-higher'}
                title={
                    <span className={'text-bold'}>
                        <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.tooltip.booking_information.title" />
                    </span>
                }
            >
                <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.tooltip.booking_information.content" />
            </Popover>
        }
    >
        <span className="rioglyph rioglyph-info-sign text-color-gray margin-left-5" />
    </OverlayTrigger>
);
