import { useState } from 'react';

type ClickHandler = () => void;

type HandleDotsClick = (page: number) => void;

interface DiagnosticFunctionItem {
    image: any;
    altText: string;
}

export type ListDiagnosticFunctionData = DiagnosticFunctionItem[];

export const useDiagnosticFunctionPagination = (dataList: ListDiagnosticFunctionData) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const currentItem: DiagnosticFunctionItem = dataList[currentPage - 1];

    const pageNumbers: number[] = [];
    dataList.forEach((value, index, array) => {
        pageNumbers.push(index + 1);
    });

    const buttonPrevClick: ClickHandler = () => {
        setCurrentPage((myCurrentPage) => (myCurrentPage === pageNumbers[0] ? pageNumbers[pageNumbers.length - 1] : myCurrentPage - 1));
    };

    const buttonNextClick: ClickHandler = () => {
        setCurrentPage((myCurrentPage) => (myCurrentPage === pageNumbers[pageNumbers.length - 1] ? pageNumbers[0] : myCurrentPage + 1));
    };

    const handleDotsClick: HandleDotsClick = (page) => {
        setCurrentPage(page);
    };

    return {
        pageNumbers,
        currentItem,
        currentPage,
        buttonPrevClick,
        buttonNextClick,
        handleDotsClick,
    };
};
