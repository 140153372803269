import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import { FormattedMessage, useIntl } from 'react-intl';
import image from '../../../../images/AxleImage.png';

export const BrakeLiningsInfoTooltip = () => {
    const intl = useIntl();

    const InfoBox = (
        <Popover
            id={'axleinfo-popover'}
            className={'margin-left-5'}
            title={
                <span className="text-bold">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.brake_linings.info_tooltip.title" />
                </span>
            }
        >
            <div className="max-width-250 bg-white text-color-black">
                <div>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.description" />
                    </p>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.warning" />
                    </p>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.critical" />
                    </p>
                </div>

                <div>
                    <span className="text-bold padding-right-4">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle_info.info" />:
                    </span>

                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle_info.info_text" />
                </div>

                <div className="text-uppercase text-center text-bold">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle_info.front_axle" />
                </div>

                <img alt={intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.axle_info.alt_text' })} src={image} className="center-block height-150" />

                <div className="text-uppercase text-center text-bold margin-bottom-10">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle_info.rear_axle" />
                </div>

                <div>
                    <i>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle_info.note" />
                    </i>
                </div>
            </div>
        </Popover>
    );

    return (
        <OverlayTrigger placement="right" overlay={InfoBox}>
            <span className="rioglyph rioglyph-info-sign margin-left-5" />
        </OverlayTrigger>
    );
};
