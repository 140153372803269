import { createContext } from 'react';

type AppContextType = {
    sidebarRef?: React.MutableRefObject<any>;
    treeRef?: React.MutableRefObject<any>;
};

const defaultAppContext: AppContextType = {};

export const AppContext = createContext(defaultAppContext);
