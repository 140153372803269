import { createSlice } from '@reduxjs/toolkit';
import { config } from '../../config';
import { IConfigState } from '../../types';

const initialState: IConfigState = config;

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
});

export default configSlice.reducer;
