import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';

export const EngineOilDtcTooltip = () => (
    <>
        <span className="margin-left-5" />
        <OverlayTrigger
            placement="right"
            overlay={
                <Tooltip>
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil.state.low.spn.tooltip" />
                </Tooltip>
            }
        >
            <span className="icon rioglyph rioglyph-info-sign text-color-dark" />
        </OverlayTrigger>
    </>
);
