/* eslint-disable sonarjs/no-duplicate-string */
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { useHomeWorkshop } from '../../../hooks/useHomeWorkshop';
import { useStoredReleaseNotes } from './useStoredReleaseNotes';
import IframeResizer from 'iframe-resizer-react';
import { config } from '../../../config';
import FeatureToggleInfoItem from './FeatureToggleInfoItem';
import ServiceInfoItem from './ServiceInfoItem';
import { SettingsIcon } from './settings/SettingsIcon';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { FeatureToggle, useFeatureToggle } from '../../../hooks/useFeatureToggle';

const AppHeader = () => {
    const appTitle = <FormattedMessage id={'intl-msg:fleetstatus.global.module_name'} />;
    const { featureToggleValue: isStencilTestFeatureEnabled } = useFeatureToggle(FeatureToggle.ENABLE_STENCIL_TEST);
    const { homeWorkshop, isLoading, error } = useHomeWorkshop();
    const { notSeenReleaseNotes } = useStoredReleaseNotes();
    const hasHomeWorkshop = error ? true : homeWorkshop?.hasHomeWorkshop;
    const navItems = [
        {
            key: 'overview',
            route: (
                <NavLink to={'/overview'} className={hasHomeWorkshop ? '' : 'disabled-nav-item'}>
                    <FormattedMessage id={'intl-msg:fleetstatus.app.page.overview'} />
                </NavLink>
            ),
        },
        {
            key: 'diagnostics',
            route: (
                <NavLink to={'/diagnostics'} className={hasHomeWorkshop ? '' : 'disabled-nav-item'}>
                    <FormattedMessage id={'intl-msg:fleetstatus.app.page.diagnostics'} />
                </NavLink>
            ),
        },
        {
            key: 'damages',
            route: (
                <NavLink to={'/damages'} className={hasHomeWorkshop ? '' : 'disabled-nav-item'}>
                    <FormattedMessage id={'intl-msg:fleetstatus.app.page.damages'} />
                </NavLink>
            ),
        },
        {
            key: 'myfleet',
            route: (
                <NavLink to={'/myfleet'} className={hasHomeWorkshop ? '' : 'disabled-nav-item'}>
                    <FormattedMessage id={'intl-msg:fleetstatus.app.page.myfleet'} />
                </NavLink>
            ),
        },
    ];
    if (isStencilTestFeatureEnabled) {
        navItems.push({
            key: 'stencil-test',
            route: (
                <NavLink to={'/stencil-test'} className={hasHomeWorkshop ? '' : 'disabled-nav-item'}>
                    <FormattedMessage id={'intl-msg:fleetstatus.app.page.stencil_test'} />
                </NavLink>
            ),
        });
    }

    const environment = import.meta.env.PROD ? 'production' : 'local';
    const userMenu = <DefaultUserMenu environment={environment} />;
    const menuUrl = config.backend.MENU_SERVICE as string;
    const appNavigator = <IframeResizer className="iFrameResizer" src={menuUrl} />;
    const homeLink = <a href={'/'} />;
    const settings = <SettingsIcon />;
    const notifications = <RioNotifications />;

    return (
        <ApplicationHeader
            label={appTitle}
            appNavigator={appNavigator}
            homeRoute={homeLink}
            navItems={isLoading ? [] : navItems}
            // eslint-disable-next-line max-len
            actionBarItems={[<FeatureToggleInfoItem key={0} />, settings, <ServiceInfoItem notSeenReleaseNotes={notSeenReleaseNotes} key={1} />, notifications, userMenu]}
        />
    );
};

export default AppHeader;
