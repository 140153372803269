import { useEffect, useState } from 'react';
import { staticReleaseNotes } from './info/servicePages/staticReleaseNotes';

const MAX_COUNT_DAYS_SHOW_RELEASE_NOTES = 30;

type LastAutomaticallyOpeningReleaseNotesPopup = {
    version: string;
    automaticallyOpening: boolean;
};

export type LastSeenReleaseNotes = {
    lastSeenDay: string; // YYYY-MM-DD -> 2020-05-21
    itemsOfLastSeenDate: number;
};

export type SetLastSeenToNow = () => void;

export const useStoredReleaseNotes = () => {
    const [lastSeenReleaseNotes, setLastSeenReleaseNotes] = useState<LastSeenReleaseNotes | null>(getInitialLastSeenReleaseNotes());

    const setLastSeenToNow: SetLastSeenToNow = () => {
        const today = getISODate(new Date());
        setLastSeenReleaseNotes({ itemsOfLastSeenDate: getNumberOfReleaseNotesOfSameDay(today), lastSeenDay: today });
    };

    const lastAutomaticallyOpeningReleaseNotesPopup: LastAutomaticallyOpeningReleaseNotesPopup | null = JSON.parse(
        localStorage.getItem('lastAutomaticallyOpeningReleaseNotesPopup') as string
    );

    const lastAutomaticallyOpeningReleaseNotesPopupString = JSON.stringify({
        version: staticReleaseNotes[0].version,
        automaticallyOpening: true,
    });

    const setLastAutomaticallyOpeningReleaseNotesPopup = () => localStorage.setItem('lastAutomaticallyOpeningReleaseNotesPopup', lastAutomaticallyOpeningReleaseNotesPopupString);

    useEffect(() => {
        if (lastSeenReleaseNotes && localStorage) {
            const lastSeenReleaseNotesString = JSON.stringify(lastSeenReleaseNotes);
            localStorage.setItem('lastSeenReleaseNotes', lastSeenReleaseNotesString);
        }
    }, [lastSeenReleaseNotes]);

    const numberOfReleaseNotesSince30DaysAgo = getNumberOfNewReleaseNotes(lastSeenReleaseNotes?.lastSeenDay || getDayOf30DaysAgo(), lastSeenReleaseNotes?.itemsOfLastSeenDate || 0);
    const notSeenReleaseNotes = Math.max(0, numberOfReleaseNotesSince30DaysAgo);

    return { notSeenReleaseNotes, setLastSeenToNow, lastAutomaticallyOpeningReleaseNotesPopup, setLastAutomaticallyOpeningReleaseNotesPopup };
};

const getInitialLastSeenReleaseNotes = (): LastSeenReleaseNotes | null => {
    const valueInLocalStorage = localStorage?.getItem('lastSeenReleaseNotes');
    return valueInLocalStorage ? (JSON.parse(valueInLocalStorage) as LastSeenReleaseNotes) : null;
};

const getDayOf30DaysAgo = (): string => {
    const today = new Date();
    return getISODate(new Date(today.setDate(today.getDate() - MAX_COUNT_DAYS_SHOW_RELEASE_NOTES)));
};

const getISODate = (date: Date): string => date.toISOString().split('T')[0];

const getNumberOfNewReleaseNotes = (lastSeenDate: string, seenAtThatDay: number) =>
    staticReleaseNotes.filter((releaseNote) => {
        const releaseNoteDate = getISODate(new Date(releaseNote.date));
        return lastSeenDate <= releaseNoteDate;
    }).length - seenAtThatDay;

const getNumberOfReleaseNotesOfSameDay = (date: string) =>
    staticReleaseNotes.filter((releaseNote) => {
        const releaseNoteDate = getISODate(new Date(releaseNote.date));
        return date === releaseNoteDate;
    }).length;
