import { FormattedMessage } from 'react-intl';
import { ENGINE_OIL_SPN_HOURS } from '../../../../utils/engineOilUtils';

const EngineOilDtcElement = () => (
    <div className="label label-danger label-condensed text-uppercase">
        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.engine_oil.engine_oil_dtc_spn_warning" values={{ hours: ENGINE_OIL_SPN_HOURS }} />
    </div>
);

export default EngineOilDtcElement;
